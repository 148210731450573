import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  CourseContentActions,
  MyClassService,
  selectActiveSubsection,
  selectIsAuthenticated,
  VideoLectureProgress,
} from '@shared';
import { distinctUntilChanged, firstValueFrom, Subscription } from 'rxjs';
import { CoursesService, LoaderService, Logger, RouterStoreService, skipUndefined, ToastService } from 'thkee-common';
const log = new Logger('LearninglabShellComponent');

@UntilDestroy()
@Component({
  selector: 'app-learninglab-shell',
  templateUrl: './learninglab-shell.component.html',
  styleUrls: ['./learninglab-shell.component.scss'],
})
export class LearninglabShellComponent implements OnInit, OnDestroy {
  fullScreen: boolean = true;
  params: any = {};
  courseContentSubscription = new Subscription();
  theaterModeActionSubscription = new Subscription();
  isAuth$ = this.store.select(selectIsAuthenticated);
  isLogged$ = this.store.select(selectIsAuthenticated).pipe(skipUndefined());

  constructor(
    private routerStore: RouterStoreService,
    private store: Store,
    private router: Router,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private myClassService: MyClassService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
        // this.store.dispatch(CourseActions.loadCourse({ courseId }));
      });
    // if (Object.keys(this.params).length) {
    //   this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
    //     if (params['preview'] === 'true') {
    //       this.store.dispatch(
    //         CourseContentActions.loadCourseContent({ product_id: this.params['courseId'], preview: true })
    //       );
    //       this.store.dispatch(
    //         CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'], preview: true })
    //       );
    //     } else {
    //       this.store.dispatch(CourseContentActions.loadCourseContent({ product_id: this.params['courseId'] }));
    //       this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
    //     }
    //     this.selectActivity(this.params['section'], this.params['subsection']);
    //   });
    // }

    // combineLatest([this.routerStore.getParams(), this.routerStore.getQuery('preview')])
    //   .pipe(distinctUntilChanged(), untilDestroyed(this))
    //   .subscribe(([params, preview]) => {
    //     this.params = params;
    //     if (preview) {
    //       this.store.dispatch(
    //         CourseContentActions.loadCourseContent({ product_id: this.params['courseId'], preview: true })
    //       );
    //       this.store.dispatch(
    //         CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'], preview: true })
    //       );
    //     } else {
    //       this.store.dispatch(CourseContentActions.loadCourseContent({ product_id: this.params['courseId'] }));
    //       this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
    //     }
    //     this.selectActivity(this.params['section'], this.params['subsection']);
    //   });

    firstValueFrom(this.routerStore.getQuery('preview')).then((preview) => {
      if (preview) {
        this.store.dispatch(
          CourseContentActions.loadCourseContent({ product_id: this.params['courseId'], preview: true })
        );
        this.store.dispatch(
          CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'], preview: true })
        );
      } else {
        this.store.dispatch(CourseContentActions.loadCourseContent({ product_id: this.params['courseId'] }));
        this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
      }
      this.selectActivity(this.params['section'], this.params['subsection']);
    });

    // Action from video js
    this.theaterModeActionSubscription.add(
      this.loaderService.response('theater-button-action').subscribe((res) => {
        res === 'loading' ? (this.fullScreen = true) : (this.fullScreen = false);
      })
    );
  }

  ngOnDestroy(): void {
    this.courseContentSubscription.unsubscribe();
    this.theaterModeActionSubscription.unsubscribe();
  }

  intervalId: ReturnType<typeof setInterval> | undefined = undefined;
  activityHandler(event: VideoLectureProgress) {
    if (event.action === 'play' && !this.intervalId) {
      this.intervalId = setInterval(() => {
        this.saveVideoPosition(event.data.currentTime);
      }, 3000);
    }
    if (event.action === 'pause' || event.action === 'ended') {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      }
    }

    if (event.action == 'full-screen') {
      this.fullScreen = !this.fullScreen;
    }
  }

  saveVideoPosition(duration: number) {
    firstValueFrom(this.store.select(selectActiveSubsection)).then((subs) => {
      this.myClassService
        .markedAsRead({ subsection: subs?.id ?? '', watched_duration: duration }, this.params['overviewcode'])
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe({
          next: (res) => {},
          error: ({ error }) => {
            this.toastService.error({
              message: error.error,
            });
          },
        });
    });
  }

  selectActivity(sectionId: string, subsectionId: string) {
    this.store.dispatch(
      CourseContentActions.selectActivity({
        sectionId,
        subsectionId,
      })
    );
  }

  // Reload
  reload() {
    let url = `/learning-lab/${this.params['courseId']}/overview/${this.params['section']}/${this.params['subsection']}`;
    this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
}
