import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration',
})
export class formatDurationPipe implements PipeTransform {
  // transform(second: number = 0, ...args: unknown[]): string {
  //   // { minute: number; second: number; output: string } - Old output
  //   if (second < 0) {
  //     throw new Error('Second cannot be negative.');
  //   }
  //   const minute = Math.floor(second / 60);
  //   const remainingSeconds = Math.floor(second % 60);
  //   let output = '';
  //   if (minute) output += minute + 'm ';
  //   if (remainingSeconds) output += remainingSeconds + 's';
  //   output = output ? output : 'Not Available';

  //   return output;
  //   //return { minute, second: remainingSeconds, output }; Old return
  // }

  transform(second: number = 0, ...args: unknown[]): string {
    if (second < 0) {
      return 'Not Available'; // Handling invalid input
    }

    const totalSeconds = Math.floor(second);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    // Checking for zero duration
    if (totalSeconds === 0) {
      return 'Not Available';
    }

    // Formatting based on the duration
    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    } else if (totalSeconds < 60) {
      return `${'00'}:${String(seconds).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  }
}
