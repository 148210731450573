import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoursesDetailsService } from '@shared/services/public/courses.service';
import { Subscription } from 'rxjs';
import SwiperCore, { Autoplay, EffectCoverflow, EffectCube, Pagination, SwiperOptions } from 'swiper';
import { Course } from 'thkee-common';

SwiperCore.use([Pagination, EffectCube, Autoplay, EffectCoverflow]);
@Component({
  selector: 'app-hero-one',
  templateUrl: './hero-one.component.html',
  styleUrls: ['./hero-one.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroOneComponent implements OnInit, OnDestroy {
  categorySlider: SwiperOptions = {
    slidesPerView: 'auto',
    effect: 'coverflow',
    centeredSlides: true,
    speed: 2000,
    grabCursor: true,
    autoHeight: true,
    autoplay: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: (index, className) => {
        return `<span class="${className} bg-primary-400"></span>`;
      },
    },
    navigation: {
      prevEl: '.prevCtg',
      nextEl: '.nextCtg',
    },
  };

  private popularCoursesSubscription = new Subscription();
  popularCourses: Partial<Course>[] = [];
  isLoadingPopularCourse: boolean = false;

  constructor(private coursesDetailsService: CoursesDetailsService) {}

  ngOnInit(): void {
    this.loadCourse();
  }

  loadCourse() {
    this.isLoadingPopularCourse = true;
    // this.popularCoursesSubscription.add(
    //   this.coursesDetailsService.getPopularCourses(params).subscribe((course) => {
    //     this.isLoadingPopularCourse = false;
    //     this.popularCourses = course.results;
    //     console.log('🚀 ~  this.popularCourses :', this.popularCourses);
    //   })
    // );
    let params = { category: 'arts-music', page: 1, page_size: 4 };
    this.popularCoursesSubscription.add(
      this.coursesDetailsService.getCourses(params).subscribe((course) => {
        this.isLoadingPopularCourse = false;
        this.popularCourses = course.results;
      })
    );
  }

  ngOnDestroy(): void {
    this.popularCoursesSubscription.unsubscribe();
  }
}
