import { Directive, ElementRef, HostListener } from '@angular/core';
import { ScrollTracker } from './scroll-tracker';

@Directive({
  selector: '[thkScrollTracker]'
})
export class ScrollTrackerDirective {
  @HostListener('scroll')
  private onScroll() {
    this.tracker.onScroll();
  }

  @HostListener('scrollend', [])
  private onScrollend(): void {
    this.tracker.onScrollend();
  }

  tracker = new ScrollTracker(this.elRef.nativeElement);

  constructor(
    private elRef: ElementRef<HTMLElement>
  ) {}
}
