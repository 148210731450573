import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { selectActiveSubsection } from '@shared';
import { ModalComponent } from '@shared/components/modal';
import { Observable, distinctUntilChanged } from 'rxjs';

interface OriginalData {
  question_id: string;
  answer: string;
  feedback: string;
  student_assignment: string;
}

@UntilDestroy()
@Component({
  selector: 'app-learning-lab-activity-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class LearninglabAssignmentComponent implements OnInit {
  @ViewChild('confirmSubmit')
  confirmSubmit!: ModalComponent;

  @Input() completed: boolean = false; // TODO: Change this if the assignment is completed
  @Input() answers: any = {}; // TODO: Changes this for actual data
  @Input() hidePrev: boolean = false;
  @Input() hideNext: boolean = false;
  @Output() event = new EventEmitter<{ action: string; data: any }>();

  // data?: PartialEntity<AssignmentDetailV2>;
  data?: any;
  title: string = '';
  fullScreen: boolean = false;
  inputValue: number = 100;
  assignmentState: 'start' | 'edit' | 'preview' | 'completed' = 'start';

  readonly form: FormGroup = new FormGroup({});
  model: Partial<any> = {};
  feedbackModel: Partial<any> = {};
  fields: FormlyFieldConfig[] = [];

  getAssignmentAnswers$!: Observable<any>;

  constructor(private store: Store) {
    if (this.answers) {
      this.model = this.answers;
    }

    this.store
      .select(selectActiveSubsection)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((assignmentDetail: any) => {
        this.data = assignmentDetail;
        this.title = assignmentDetail?.assignment?.title ?? '';

        this.assignmentState = 'start';
        this.fullScreen = false;
        this.inputValue = 100;

        if (this.completed === true) {
          this.assignmentState = 'completed';
          this.model = this.answers;
        }

        if (assignmentDetail) {
          let submission: OriginalData[] = assignmentDetail?.submission || [];
          if (submission.length) {
            this.assignmentState = 'completed';
            this.model = this.restructureData(submission);
            this.feedbackModel = this.restructureFeedbackData(submission);
          }
        }

        this.initfield();
      });
  }

  restructureData(data: OriginalData[]): { [key: string]: string } {
    const restructuredData: { [key: string]: string } = {};
    data.forEach((item) => {
      restructuredData[item.question_id] = item.answer;
    });
    return restructuredData;
  }

  restructureFeedbackData(data: OriginalData[]): { [key: string]: string } {
    const restructuredData: { [key: string]: string } = {};
    data.forEach((item) => {
      restructuredData[item.question_id] = item.feedback;
    });
    return restructuredData;
  }

  ngOnInit(): void {}

  startAssignment() {
    this.emitEvent('assignment_start', this);
    this.assignmentState = 'edit';
  }

  initfield() {
    if (this.data?.assignment?.questions) {
      this.fields = [];
      this.data?.assignment?.questions.forEach((question: { id: any; question: any }, i: number) => {
        this.fields.push({
          key: question.id,
          type: 'textarea',
          props: {
            label: `${i + 1}. ${question.question}`,
            minLength: 6,
            maxLength: 1000,
            placeholder: $localize`Your Answer...`,
            minHeight: '100px',
            maxHeight: '150px',
            required: true,
          },
          validation: {
            messages: {
              minLength: $localize`Answer can't be lower than 6 characters.`,
            },
          },
        });
      });
    }
  }

  previewAssignment() {
    this.emitEvent('assignment_preview', this);
    this.assignmentState = 'preview';
  }

  confirmSubmitAssignment() {
    this.emitEvent('assignment_pre_submit', this);
    this.confirmSubmit.open();
  }

  submitAssignment() {
    this.emitEvent('assignment_submit', this);
    this.model = {}; // After submit clear the model
  }

  emitEvent(action: string, data: any = {}) {
    this.event.emit({ action, data });
    if (action == 'zoom-in' || action == 'zoom-out') {
      this.zoom(action);
    }
  }

  zoom(action: 'zoom-in' | 'zoom-out') {
    if (action == 'zoom-in' && this.inputValue != 150) {
      this.inputValue = this.inputValue + 10;
    }
    if (action == 'zoom-out' && this.inputValue != 50) {
      this.inputValue = this.inputValue - 10;
    }
  }
}
