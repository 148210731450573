<div id="accordion-collapse" data-accordion="collapse">
  <div class="item mb-4">
    <div class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3" (click)="faq.one = !faq.one">
      <h5 [ngClass]="faq.one ? 'font-bold' : 'font-normal'" class="text-sm transition-all md:text-base" i18n>
        What help is available if I face technical difficulties while taking a course?
      </h5>
      <svg-icon
        src="@assets/images/icons/plus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="!faq.one"
      ></svg-icon>
      <svg-icon
        src="@assets/images/icons/minus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="faq.one"
      ></svg-icon>
    </div>
    <div
      class="content bg-white/50 px-2 pt-2 text-sm text-slate-500 transition-all md:text-base"
      [ngClass]="faq.one ? 'block' : 'hidden'"
    >
      <p i18n>
        If you encounter any technical issues, you can access our Help Center for troubleshooting tips. Additionally, our dedicated support team is available to assist you via email or live chat to resolve any problems promptly.
      </p>
    </div>
  </div>
  <div class="item mb-4">
    <div class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3" (click)="faq.two = !faq.two">
      <h5 [ngClass]="faq.two ? 'font-bold' : 'font-normal'" class="text-sm transition-all md:text-base" i18n>
        How do I reset my password if I forget it?
      </h5>
      <svg-icon
        src="@assets/images/icons/plus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="!faq.two"
      ></svg-icon>
      <svg-icon
        src="@assets/images/icons/minus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="faq.two"
      ></svg-icon>
    </div>
    <div
      class="content bg-white/50 px-2 pt-2 text-sm text-slate-500 transition-all md:text-base"
      [ngClass]="faq.two ? 'block' : 'hidden'"
    >
      <p i18n>
        If you've forgotten your password, click on the "Forgot Password?" link on the login page. You'll receive an email with instructions to reset your password and regain access to your account.
      </p>
    </div>
  </div>
  <div class="item mb-4">
    <div
      class="title flex cursor-pointer items-center rounded-md bg-slate-100 px-4 py-3"
      (click)="faq.three = !faq.three"
    >
      <h5 [ngClass]="faq.three ? 'font-bold' : 'font-normal'" class="text-sm transition-all md:text-base" i18n>
        Can I get a refund if I'm not satisfied with a course?
      </h5>
      <svg-icon
        src="@assets/images/icons/plus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="!faq.three"
      ></svg-icon>
      <svg-icon
        src="@assets/images/icons/minus.svg"
        class="ar:ml-0 ar:mr-auto ml-auto h-6 w-6 text-slate-600"
        *ngIf="faq.three"
      ></svg-icon>
    </div>
    <div
      class="content bg-white/50 px-2 pt-2 text-sm text-slate-500 transition-all md:text-base"
      [ngClass]="faq.three ? 'block' : 'hidden'"
    >
      <p i18n>
        Yes, Thkee provides a satisfaction guarantee! If you're not satisfied with a qualified course you've purchased, you can request a refund within 30 days, as long as you've viewed less than 30% of the course. We want to ensure you are completely happy with your learning experience! 
      </p>
    </div>
  </div>
</div>
