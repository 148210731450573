import { PayoutMethodName, PayoutRequestStatus, PayoutTransactionStatus, PayoutType } from 'thkee-common';

export const PAYOUT_ACTIVITY_ICONS = {
  // Requested: '@assets/images/icons/payouts/payout-request-icon.svg',
  // 'Mark As Paid': '@assets/images/icons/payouts/payout-failed-icon.svg',
  // Failed: '@assets/images/icons/payouts/payout-deactive-icon.svg',
  // 'Mark As Inactive': '@assets/images/icons/payouts/paypal-icon.svg',
  // 'Mark As Active': '@assets/images/icons/payouts/payonear-icon.svg',

  requested: '@assets/images/icons/payouts/payout-request-icon.svg',
  failed: '@assets/images/icons/payouts/payout-failed-icon.svg',
  deactivated: '@assets/images/icons/payouts/payout-deactive-icon.svg',
  // activated: '@assets/images/icons/payouts/payonear-icon.svg',
  // paid: '@assets/images/icons/payouts/payout-deactive-icon.svg',
  // ready:''
};

export const PAYOUT_REQUEST_STATUS_BADGES: Record<PayoutRequestStatus, string> = {
  requested: 'badge badge-solid-primary',
  processing: 'badge badge-solid-green',
  paid: 'badge badge-solid-slate',
  failed: 'badge badge-solid-red',
  ready: 'badge badge-solid-green',
  deactivated: 'badge badge-solid-slate',
};

export const PAYOUT_REQUEST_STATUS_NAMES: Record<PayoutRequestStatus, string> = {
  requested: $localize`Requested`,
  processing: $localize`Processing`,
  paid: $localize`Paid`,
  failed: $localize`Failed`,
  ready: $localize`Ready`,
  deactivated: $localize`Deactivated`,
};

export const PAYOUT_TRANSACTION_STATUS_BADGES: Record<PayoutTransactionStatus, string> = {
  pending: 'badge badge-solid-primary',
  available: 'badge badge-solid-green',
  requested: 'badge badge-solid-slate',
  ready: 'badge badge-solid-green',
  paid: 'badge badge-solid-green',
  failed: 'badge badge-solid-red',
  on_hold: 'badge badge-solid-slate',
  refunded: 'badge badge-solid-slate',
};

export const PAYOUT_TRANSACTION_STATUS_NAMES: Record<PayoutTransactionStatus, string> = {
  pending: $localize`Pending`,
  available: $localize`Available`,
  requested: $localize`Requested`,
  ready: $localize`Ready`,
  paid: $localize`Paid`,
  failed: $localize`Failed`,
  on_hold: $localize`On-hold`,
  refunded: $localize`Refunded`,
};

export const PAYOUT_TYPES = {
  [PayoutType.monthly]: $localize`Monthly Payout`,
  [PayoutType.onDemand]: $localize`On-demand Payout`,
};

export const PAYOUT_METHOD_NAMES: Record<PayoutMethodName, string> = {
  bank_account: $localize`Bank Account`,
  paypal: $localize`Paypal`,
  payoneer: $localize`Payoneer`,
};
