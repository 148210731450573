<ng-container *ngIf="{ courseId: courseId$ | async, canSubmitReview: canSubmitReview$ | async, canWithdrawReview: canWithdrawReview$ | async } as vm">
  <div class="instructor-sidebar flex min-h-screen flex-col bg-slate-900 pb-6 pt-0 text-white">
    <div class="logo flex bg-slate-800 px-8 py-4">
      <a routerLink="/">
        <svg-icon src="@assets/images/logo-white.svg" class="block h-7 w-auto"></svg-icon>
      </a>
    </div>
    <div class="menu-items my-6">
      <ul class="text-lg">
        <li>
          <a [class.active]="isActive('intended-learners')"
            [routerLink]="'/instructor/course/' + vm.courseId + '/intended-learners'">
            <svg-icon src="@assets/images/icons/academic-cap2.svg" class="h-5 w-5"></svg-icon><span i18n>Intended
              Learners</span>
          </a>
        </li>
        <li>
          <a [class.active]="isActive('details')" [routerLink]="'/instructor/course/' + vm.courseId + '/details'">
            <svg-icon src="@assets/images/icons/clipboard-document-list-solid.svg" class="h-5 w-5"></svg-icon>
            <span i18n>Details</span>
          </a>
        </li>
        <li>
          <a [class.active]="isActive('/curriculum')"
            [routerLink]="'/instructor/course/' + vm.courseId + '/curriculum'">
            <svg-icon src="@assets/images/icons/book-solid.svg" class="h-5 w-5"></svg-icon><span i18n>Curriculum</span>
          </a>
        </li>
        <li>
          <a [class.active]="isActive('/promotions')"
            [routerLink]="'/instructor/course/' + vm.courseId + '/promotions'">
            <svg-icon src="@assets/images/icons/receipt-percent-solid.svg" class="h-5 w-5"></svg-icon>
            <span i18n>Promotions</span>
          </a>
        </li>
        <li>
          <a [class.active]="isActive('/pricing')" [routerLink]="'/instructor/course/' + vm.courseId + '/pricing'">
            <svg-icon src="@assets/images/icons/tag-solid.svg" class="h-5 w-5"></svg-icon><span i18n>Pricing</span>
          </a>
        </li>
        <li>
          <a [class.active]="isActive('/tips') || isActiveChild('/instructor/course/'+vm.courseId+'/tips', 5)"
            [routerLink]="'/instructor/course/' + vm.courseId + '/tips'">
            <svg-icon src="@assets/images/icons/bulb-solid.svg" class="h-5 w-5"></svg-icon><span i18n>Tips</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="footing mt-auto flex w-full flex-col gap-3 px-6 text-center">
      <button *ngIf="vm.canSubmitReview" [disabled]="isSubmittedForReview" class="btn btn-primary px-0" (click)="confirmSubmitForReview()">
        <span i18n>Submit for Review</span>
        <ng-container *ngIf="isSubmittedForReview">
          <ng-container *ngTemplateOutlet="loaderWhite"></ng-container>
        </ng-container>
      </button>
      <button *ngIf="vm.canWithdrawReview" [disabled]="isSubmittedForReview" class="btn btn-primary px-0" (click)="submitReviewWithdraw()">
        <span i18n>Withdraw Submission</span>
        <ng-container *ngIf="isSubmittedForReview">
          <ng-container *ngTemplateOutlet="loaderWhite"></ng-container>
        </ng-container>
      </button>
    </div>
  </div>
  <app-common-modal-confirm #submitForReviewConfirmModal
    i18n-title
    i18n-message
    title="Submit for Review"
    message="Are you sure you want to submit this course for review?"
    (confirm)="submitForReview()">
  </app-common-modal-confirm>
  <common-modal #submitForReviewMessagesModal i18n-title title="Why can't I submit?" customClass="text-base sm:w-full sm:max-w-md">
    <ng-container content>
      <div class="validation-messages flex flex-col gap-3 p-6 text-slate-600">
        <ng-container *ngIf="intendedLearnersErrors.length > 0">
          <div>
            <h6 i18n>Intended Learners</h6>
            <ul>
              <ng-container *ngFor="let msg of intendedLearnersErrors">
                <li>{{ msg }}</li>
              </ng-container>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="detailsErrors.length > 0">
          <div>
            <h6 i18n>Details</h6>
            <ul>
              <ng-container *ngFor="let msg of detailsErrors">
                <li>{{ msg }}</li>
              </ng-container>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="curriculumErrors.length > 0">
          <div>
            <h6 i18n>Curriculum</h6>
            <ul>
              <ng-container *ngFor="let msg of curriculumErrors">
                <li>{{ msg }}</li>
              </ng-container>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="pricingErrors.length > 0">
          <h6 i18n>Promotions</h6>
          <ul>
            <ng-container *ngFor="let msg of pricingErrors">
              <li>{{ msg }}</li>
            </ng-container>
          </ul>
        </ng-container>

        <ng-container *ngIf="annotationErrors.length > 0">
          <h6 i18n>Feedback</h6>
          <ul>
            <ng-container *ngFor="let msg of annotationErrors">
              <li>{{ msg }}</li>
            </ng-container>
          </ul>
        </ng-container>

        <ng-container *ngIf="annotationErrors.length > 0">
          <h6 i18n>Transcoding</h6>
          <ul>
            <li>{{ transcodingErrors }}</li>
          </ul>
        </ng-container>
      </div>
      <div class="flex justify-end gap-2 border-t border-slate-200 px-6 py-4">
        <button class="btn btn-primary" (click)="submitForReviewMessagesModal.close()">
          <span i18n>Close</span>
        </button>
      </div>
    </ng-container>
  </common-modal>
</ng-container>

<ng-template #loaderWhite>
  <svg class="text-slate-800 h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
    </path>
  </svg>
</ng-template>
