import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy } from '@shared/utils';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  CourseActions,
  dragdropState,
  FileProcessingState,
  LoaderService,
  selectSelectedActivityId,
  selectUploads,
} from 'thkee-common';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-DragDropInfo',
  template: `
    <ng-container *ngIf="{ uploads: uploads$ | async, lectureId: lectureId$ | async } as vm">
      <ng-container *ngIf="vm.uploads && vm.lectureId">
        <!-- Upload in progress -->
        <div
          *ngIf="
            vm.uploads![vm.lectureId!]?.status === 'upload-progress' ||
            vm.uploads![vm.lectureId!]?.status === 'uploading'
          "
          class="flex flex-col gap-3"
        >
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/file-up.svg"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>Uploading...</div>
            <div class="estimate" i18n>
              Estimated time remaining: <span>{minutes, plural, =1 {1 minute} other { {{ minutes }} minutes} }</span>
            </div>
          </div>
          <div class="progress-status flex items-center gap-3">
            <div>
              <svg-icon src="@assets/images/icons/file.svg" class="block"></svg-icon>
            </div>
            <div class="grow">
              <div class="flex text-xs font-medium">
                <div class="grow truncate">{{ vm.uploads![vm.lectureId!].fileName | truncate : 50 : '...' }}</div>
                <div class="text-slate-400">{{ vm.uploads![vm.lectureId!].progressPercent }}%</div>
              </div>
              <div class="progress-container">
                <div class="progress" [style.width.%]="vm.uploads![vm.lectureId!].progressPercent"></div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-sm btn-outline-red" (click)="emitClickEvent('abort')">
              <svg-icon src="@assets/images/icons/x-circle.svg" class="btn-icon"></svg-icon>
              <span i18n>Abort</span>
            </button>
          </div>
        </div>
        <!-- Upload Success -->
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'upload-complete'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/check-circle-solid.svg" class="text-green"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Uploaded.</div>
            <div class="estimate" i18n>File uploaded successfully done</div>
          </div>
        </div>
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'processing'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/hourglass.svg" class="text-green"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Processing.</div>
            <div class="estimate" i18n>Video is processing now</div>
          </div>
        </div>
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'failed'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/exclamation-triangle-solid.svg" class="text-red-500"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Processing Failed.</div>
            <div class="estimate" i18n>Your uploaded file processing is failed</div>
          </div>
          <div class="text-center">
            <button class="btn btn-sm btn-outline-red" (click)="emitClickEvent('reset')">
              <svg-icon src="@assets/images/icons/x-circle.svg" class="btn-icon"></svg-icon>
              <span i18n>Retry</span>
            </button>
          </div>
        </div>
        <!-- Uploaded Preview -->
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'completed'" class="-m-6">
          <div class="preview relative min-h-[264px] overflow-hidden rounded-lg">
            <!-- <app-lib-video-player [URL]="uploadState.fileURL"></app-lib-video-player> -->
            <ng-container *ngIf="sources$">
              <app-thk-video-player
                [sources$]="sources$"
                [settingsButton]="false"
                [relatedMenu]="false"
                [qualityMenu]="false"
                [buttonRewind]="false"
                [autoplay]="true"
                [singlePlayer]="false"
                [theaterButton]="false"
                [poster]="''"
              >
              </app-thk-video-player>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="vm.uploads![vm.lectureId!]?.status === 'loading'"
          [thkLibProcessing]="vm.uploads![vm.lectureId!]?.status === 'loading'"
          class="flex h-[264px] w-full items-center justify-center"
        ></div>
      </ng-container>
    </ng-container>
  `,
})
export class FormlyFileDragDropInfoComponent implements OnInit, OnDestroy {
  @Input() field!: FormlyFieldConfig;
  @Input() fileStatus: FileProcessingState | undefined;
  @Input() sources$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input() uploadState: dragdropState = {
    fileName: 'Uploading...',
    currentProgress: 0,
    viewState: '',
    fileURL: '',
  };
  @Output() clickEvent = new EventEmitter<string>();
  minutes = 20;

  private subscriptions = new Subscription();
  processingStatus: string = '';
  lectureId: string = '';
  isLoading$ = this.loaderService.response('file-processing');
  processingInfo: { [key: string]: { status: string; error?: string } } = {};

  uploads$ = this.store.select(selectUploads);
  lectureId$ = this.store.select(selectSelectedActivityId);

  constructor(private loaderService: LoaderService, private store: Store) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.lectureId$.subscribe((lectureId) => {
        this.lectureId = lectureId;
      })
    );
  }

  emitClickEvent(action: string) {
    this.clickEvent.emit(action);
    this.store.dispatch(CourseActions.uploadStatusUpdate({ referenceId: this.lectureId, status: 'init' }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
