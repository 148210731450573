import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  Certificate,
  CourseContentActions,
  CourseSettingsService,
  ROUTE_HOME,
  selectCourseContent,
  selectCoursesCertificate,
  selectUser,
} from '@shared';
import { distinctUntilChanged } from 'rxjs';
import { RouterStoreService, User } from 'thkee-common';
@UntilDestroy()
@Component({
  selector: 'app-learninglab-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class LearninglabHeaderComponent implements OnInit {
  homeUrl: string = ROUTE_HOME;

  course$ = this.store.select(selectCourseContent);
  courseCertificate$ = this.store.select(selectCoursesCertificate);

  certificateDetail!: Certificate;
  params: Params = {};
  user?: User;
  isPreviewCourse: boolean = false;
  queryParams: Params = {};

  title = inject(Title);
  pageTitle = $localize`Learning Lab`;

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private courseSettingsService: CourseSettingsService
  ) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
      });

    this.getNotificationSettings(this.params['courseId']);
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.queryParams = params;
      if (params['preview'] === 'true') {
        this.isPreviewCourse = true;
      } else {
        this.isPreviewCourse = false;
        this.getCertificateInfos();
      }
    });

    this.course$.pipe(untilDestroyed(this)).subscribe((course) => {
      // Page title
      this.title.setTitle(`${this.pageTitle} | ${course.courseOrerview.title}`);
    });

    this.loadUser();
  }

  new_announcements_emails: boolean = false;
  promotional_emails: boolean = false;
  private getNotificationSettings(courseId: string) {
    this.courseSettingsService
      .getStudentNotificationSettings(courseId)
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        this.new_announcements_emails = settings.receive_course_educational_announcements_emails;
        this.promotional_emails = settings.receive_course_promotional_announcements_emails;
      });
  }

  updateNotificationSettings() {
    const payload = {
      product_id: this.params['courseId'],
      receive_course_promotional_announcements_emails: this.promotional_emails,
      receive_course_educational_announcements_emails: this.new_announcements_emails,
    };
    this.courseSettingsService
      .updateStudentNotificationSettings(payload)
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {});
  }

  private loadUser() {
    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
      });
  }

  private getCertificateInfos() {
    this.store.dispatch(CourseContentActions.loadCourseCertificate({ product_id: this.params['courseId'] }));
    this.store
      .select(selectCoursesCertificate)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((data) => {
        this.certificateDetail = data;
      });
  }

  get progress(): number {
    if (this.certificateDetail) {
      return this.certificateDetail?.progress;
    }
    return 0;
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  back() {
    this.location.back();
  }

  goToNote() {
    let url = `/learning-lab/${this.params['courseId']}/notes/${this.params['section']}/${this.params['subsection']}/${this.params['overviewcode']}`;
    this.router.navigate([url]);
  }
}
