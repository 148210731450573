<ng-container *ngIf="type==='static'; else dynamicPage">
  <nav aria-label="Page navigation example">
    <ul class="inline-flex gap-2">
      <li>
        <a href="#" class="btn border-0 bg-slate-50 px-2.5">
          <svg-icon src="@assets/images/icons/chevron-left.svg" class="btn-icon h-5 w-5 rtl:hidden"></svg-icon>
          <svg-icon src="@assets/images/icons/chevron-right.svg" class="btn-icon h-5 w-5 ltr:hidden"></svg-icon>
        </a>
      </li>
      <li>
        <a href="#" class="btn border-0 bg-slate-50 text-sm">1</a>
      </li>
      <li>
        <a href="#" class="btn border-0 bg-slate-50 text-sm">2</a>
      </li>
      <li>
        <a href="#" aria-current="page" class="btn btn-primary border-0 text-sm">3</a>
      </li>
      <li>
        <a href="#" class="btn border-0 bg-slate-50 text-sm">...</a>
      </li>
      <li>
        <a href="#" class="btn border-0 bg-slate-50 text-sm">4</a>
      </li>
      <li>
        <a href="#" class="btn border-0 bg-slate-50 text-sm">5</a>
      </li>
      <li>
        <a href="#" class="btn border-0 bg-slate-50 px-2.5 text-sm">
          <svg-icon src="@assets/images/icons/chevron-right.svg" class="btn-icon h-5 w-5 rtl:hidden"></svg-icon>
          <svg-icon src="@assets/images/icons/chevron-left.svg" class="btn-icon h-5 w-5 ltr:hidden"></svg-icon>
        </a>
      </li>
    </ul>
  </nav>
</ng-container>

<ng-template #dynamicPage>
  <ng-container *ngIf="paginationData.totalPages > 0">
    <div class="pagination flex items-center justify-between">
      <div class="counts text-sm font-medium">
        {{
          paginationData.currentPage === paginationData.startPage
            ? paginationData.currentPage
            : (paginationData.currentPage * paginationData.pageSize) + 1 - (paginationData.pageSize)
        }}
        to
        {{
          paginationData.currentPage === paginationData.endPage
          ? paginationData.totalRecords
          : paginationData.currentPage * paginationData.pageSize
        }}
        of {{paginationData.totalRecords}} records
      </div>
      <nav aria-label="Page navigation example">
        <ul class="inline-flex gap-2">
          <li (click)="paginateAction('prev')">
            <button [disabled]="paginationData.currentPage === 1"
              class="btn border-0 bg-slate-50 px-2.5">
              <svg-icon src="@assets/images/icons/chevron-left.svg" class="btn-icon rtl:hidden h-5 w-5"></svg-icon>
              <svg-icon src="@assets/images/icons/chevron-right.svg" class="btn-icon ltr:hidden h-5 w-5"></svg-icon>
            </button>
          </li>
          <li (click)="switchPage(page)" *ngFor="let page of paginationData.pages">
            <a [ngClass]="page===paginationData.currentPage?' bg-primary-600':'bg-slate-50'"
              class="btn border-0 text-sm">{{page}}</a>
          </li>
          <li (click)="paginateAction('next')">
            <button [disabled]="paginationData.currentPage === paginationData.endPage"
              class="btn border-0 bg-slate-50 px-2.5 text-sm">
              <svg-icon src="@assets/images/icons/chevron-right.svg" class="btn-icon rtl:hidden h-5 w-5"></svg-icon>
              <svg-icon src="@assets/images/icons/chevron-left.svg" class="btn-icon ltr:hidden h-5 w-5"></svg-icon>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </ng-container>
</ng-template>
