<!-- Main Footer -->
<div class="footer relative z-20 bg-slate-900 pb-4 pt-12 text-slate-400">
  <div class="container">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-3">
      <div class="flex max-w-xs flex-col gap-4 xl:gap-5">
        <svg-icon src="@assets/images/logo-plain.svg" class="w-24 text-white lg:w-36"></svg-icon>
        <div class="text-sm lg:text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        <div class="language relative w-40">
          <app-navigation position="left">
            <ng-container button>
              <button class="btn btn-dark btn-sm lg:btn-base border-slate-400">
                <svg-icon class="btn-icon" src="@assets/images/icons/globe.svg"></svg-icon>
                <span>{{currentLang}}</span>
                <svg-icon class="btn-icon h-4 w-4 transition-all" src="@assets/images/icons/chevron-down.svg">
                </svg-icon>
              </button>
            </ng-container>
            <ng-container content>
              <!-- Remove other restricted actions -->
              <div class="flex flex-col p-1">
                <a *ngFor="let lang of supportLanguages" (click)="changeLanguage(lang.code)"
                  class="btn btn-white btn-sm hover:bg-primary-50 justify-start">{{lang.name}}</a>
              </div>
            </ng-container>
          </app-navigation>
        </div>
      </div>
      <div class="col-span-2">
        <div class="mt-6 flex gap-4 sm:mt-0 sm:justify-end xl:gap-24">
          <div class="min-w-[150px] md:min-w-[200px]">
            <h5 class="lg:h5 mb-4 text-base font-medium lg:mb-6" i18n>Company</h5>
            <ul class="flex flex-col gap-3 text-sm text-slate-100 lg:text-base">
              <li><a href="https://about.thkee.com/who" target="_blank" i18n>About us</a></li>
              <li><a href="#" i18n>Contact us</a></li>
              <li><a href="https://about.thkee.com/teaching-center" target="_blank" i18n>Teach on Thkee</a></li>
            </ul>
          </div>
          <div class="min-w-[150px] md:min-w-[200px]">
            <h5 class="lg:h5 mb-4 text-base font-medium lg:mb-6" i18n>Service</h5>
            <ul class="flex flex-col gap-3 text-sm text-slate-100 lg:text-base">
              <li><a href="https://about.thkee.com" target="_blank" i18n>Help & support</a></li>
              <li><a href="https://about.thkee.com/careers/" target="_blank" i18n>Careers</a></li>
              <li><a href="https://thkee.com/terms-of-use/" target="_blank" i18n>Terms of Service</a></li>
              <li><a href="https://thkee.com/privacy-policy/" target="_blank" i18n>Privacy Policy</a></li>
            </ul>
          </div>
          <div class="hidden min-w-[150px] md:min-w-[200px] lg:block">
            <ng-container *ngTemplateOutlet="social"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="my-4 border-t border-slate-800 py-4 text-center lg:hidden">
      <ng-container *ngTemplateOutlet="social"></ng-container>
    </div>
    <div
      class="copyright border-t-none mt-4 flex justify-center gap-4 border-slate-800 py-3 text-sm text-slate-500 lg:mt-8 lg:justify-between lg:border-t">
      <div>2022 thkee.com</div>
      <div i18n>All rights reserved.</div>
    </div>
  </div>
</div>

<ng-template #social>
  <h5 class="lg:h5 mb-4 text-base font-medium lg:mb-6" i18n>Social</h5>
  <ul class="mb-4 flex items-center justify-center gap-3 text-slate-100 sm:mb-6 lg:justify-start">
    <li>
      <a href="#">
        <svg-icon src="@assets/images/icons/facebook.svg" class="w-4"></svg-icon>
      </a>
    </li>
    <li>
      <a href="#">
        <svg-icon src="@assets/images/icons/twitter.svg" class="w-4"></svg-icon>
      </a>
    </li>
    <li>
      <a href="#">
        <svg-icon src="@assets/images/icons/linkedin.svg" class="w-3.5"></svg-icon>
      </a>
    </li>
    <li>
      <a href="#">
        <svg-icon src="@assets/images/icons/youtube.svg" class="w-5"></svg-icon>
      </a>
    </li>
  </ul>
  <ul class="flex items-center justify-center gap-2 text-slate-100 lg:justify-start">
    <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
      <a href="#">
        <svg-icon src="@assets/images/apple-pay.svg" class="w-full"></svg-icon>
      </a>
    </li>
    <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
      <a href="#">
        <svg-icon src="@assets/images/paypal.svg" class="w-full"></svg-icon>
      </a>
    </li>
    <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
      <a href="#">
        <svg-icon src="@assets/images/visa.svg" class="w-full"></svg-icon>
      </a>
    </li>
    <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
      <a href="#">
        <svg-icon src="@assets/images/master-card.svg" class="w-full"></svg-icon>
      </a>
    </li>
    <li class="flex h-6 w-10 items-center justify-center rounded-sm bg-[#162F6A] p-1">
      <a href="#">
        <svg-icon src="@assets/images/other-card.svg" class="w-full"></svg-icon>
      </a>
    </li>
  </ul>
</ng-template>