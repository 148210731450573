import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoginContext } from '@shared/models';
import { AuthenticationService } from '@shared/services/auth/authentication.service';
import { AuthActions } from '@shared/store';
import { tap } from 'rxjs';
import { ActionTrackerService, LoaderService, Logger, NotificationMessage } from 'thkee-common';
const log = new Logger('Shared/app-form-login');

const loginErrors = {
  default: $localize`There are something wrong. Please try again!`,
  no_active_account: $localize`No active account found with the given credentials.`,
};

@UntilDestroy()
@Component({
  selector: 'app-form-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class FormLoginComponent implements OnInit {
  private readonly TRACKER_ID = ActionTrackerService.uniqToken();
  messages = <NotificationMessage[]>[];
  loggingIn$ = this.actionTracker
    .isProcessing(this.TRACKER_ID)
    .pipe(tap((processing) => (processing ? this.form.disable() : this.form.enable())));
  readonly form = new FormGroup({});
  readonly fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      props: {
        label: $localize`Email`,
        placeholder: $localize`Enter your email`,
        type: 'email',
        required: true,
      },
      validators: {
        validation: ['email'],
      },
    },
    {
      key: 'password',
      type: 'input-password',
      props: {
        label: $localize`Password`,
        placeholder: $localize`Enter your password`,
        required: true,
        type: 'password',
      },
    },
  ];

  constructor(
    private readonly store: Store,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private actionTracker: ActionTrackerService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.actionTracker
      .getFailed(this.TRACKER_ID)
      .pipe(untilDestroyed(this))
      .subscribe(({ metadata }) => {
        this.messages = (metadata?.messages || []).map((message) => {
          return { type: message.type, message: loginErrors[message.message] || loginErrors.default };
        });
      });
    this.reserveRedirectPage();
  }

  onLogin(): void {
    if (this.form.valid) {
      this.messages = [];
      this.store.dispatch(
        AuthActions.login({ data: { ...this.form.value, remember: true } as LoginContext, trackerId: this.TRACKER_ID })
      );
    }
  }

  private reserveRedirectPage() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((query) => {
      if (query['redirect']) {
        this.authService.loginActionState.next({ openLoginModal: false, redirect: query['redirect'] });
      }
    });
  }
}
