import { ReferralAffiliateState } from '@shared/models/referral-affiliate';
import { PaymentMethodsList } from 'thkee-common';

export type CheckoutState = {
  error: object;
  paymentMethods: PaymentMethodsList;
  verifiedPayment: any;
  order: any;
};

export const initialCheckoutState: CheckoutState = {
  error: {},
  paymentMethods: [],
  verifiedPayment: {},
  order: {},
};

export interface CheckoutPayload {
  success_url: string;
  cancel_url: string;
  card_token?: string;
  payment_method: string;
  payables: string[];
  referrals?: ReferralAffiliateState;
  save_card?: boolean;
}

// Save card
export interface SaveCardModel {
  id: string;
  brand: string;
  exp_year: number;
  exp_month: number;
  last_four: string;
  is_active: boolean;
  provider?: number;
}

export interface AlternativeOptionModel {
  currency_code: string;
  payables: Array<string>;
}
