<div class="relative">
  <ng-container *ngIf="isLoading; else loadedReviews">
    <div [thkLibProcessing]="isLoading" [size]="'lg'" variant="primary" class="h-[300px] w-full flex items-center justify-center"></div>
  </ng-container>
  <ng-template #loadedReviews>
    <ng-container *ngIf="currentActivity; else noActivity">
      <ng-container *ngTemplateOutlet="activity"></ng-container>
    </ng-container>
    <ng-template #noActivity>
      <ng-container *ngTemplateOutlet="emptyActivity"></ng-container>
    </ng-template>
  </ng-template>
</div>

<ng-template #activity>
  <app-learning-lab-activity-video (event)="activityHandler($event)"
    *ngIf="currentActivity.type === 'lecture' && currentActivity.lecture?.type === 'video'"
    [hidePrev]="prev.length ? false : true" [hideNext]="next.length ? false : true" [prevData]="prevData"
    [nextData]="nextData"></app-learning-lab-activity-video>
  <app-learning-lab-activity-article (event)="activityHandler($event)"
    *ngIf="currentActivity.type === 'lecture' && currentActivity.lecture?.type === 'article'"
    [hidePrev]="prev.length ? false : true" [hideNext]="next.length ? false : true"></app-learning-lab-activity-article>
  <app-learning-lab-activity-quiz (event)="activityHandler($event)" *ngIf="currentActivity.type === 'quiz'">
  </app-learning-lab-activity-quiz>
  <app-learning-lab-activity-assignment (event)="activityHandler($event)" *ngIf="currentActivity.type === 'assignment'">
  </app-learning-lab-activity-assignment>
  <ng-container *ngIf="currentActivity?.type === 'completed'">
    <ng-container *ngTemplateOutlet="completedActivity"></ng-container>
  </ng-container>
</ng-template>
<ng-template #emptyActivity>
  <div class="py-12 text-center">
    <svg-icon src="@assets/images/not-found.svg" class="mx-auto my-8 block w-72"></svg-icon>
    <h3 class="mb-3" i18n>Oh man, You don't supposed to see this!</h3>
    <p i18n>Please find your way to the <a href="../">homepage</a> or get help <a href="#">here</a>. And remember you didn't see anything!</p>
  </div>
</ng-template>
<ng-template #completedActivity>
  <div class="py-12 text-center bg-slate-800 min-h-[477px] flex flex-col items-center justify-center overflow-hidden">
    <svg-icon class="w-full absolute left-0 top-0 animate opacity-0" src="@assets/images/confetti.svg"></svg-icon>
    <!-- <svg-icon src="@assets/images/career-promotion.svg" class="mx-auto my-8 block w-72"></svg-icon> -->
    <h3 class="font-bold text-5xl mb-3 text-slate-500 text-center greeting-text-animate" i18n>Congratulation!</h3>
    <p class="font-bold text-lg text-slate-500 text-center greeting-text-animate" i18n>You have successfully completed this course :)</p>
  </div>
</ng-template>
