import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoursesContent } from './learning-lab.model';

export const selectCourseContentState = createFeatureSelector<CoursesContent>('courseContent');
export const selectCourseContent = createSelector(selectCourseContentState, (entities) => entities);
export const selectActiveSubsection = createSelector(selectCourseContentState, (state) =>
  state.subsections.find((sec) => sec.chain_id === state.subsectionId)
);
export const selectActiveSectionID = createSelector(selectCourseContentState, (state) => state.sectionId || '');
export const selectActiveSubSectionID = createSelector(selectCourseContentState, (state) => state.subsectionId || '');
export const selectCompletedActivity = createSelector(selectCourseContentState, (state) => state.completedActivity);
export const selectCoursesCertificate = createSelector(selectCourseContentState, (state) => state.certificate);
export const selectActiveSubsectionLists = createSelector(selectCourseContentState, (state) => state.subsections);
