<div class="instructor-sidebar flex min-h-screen flex-col bg-slate-900 py-6 text-white">
  <div class="logo flex px-6">
    <a routerLink="/">
      <svg-icon src="@assets/images/logo-white.svg" class="block h-8 w-auto"></svg-icon>
    </a>
  </div>
  <div class="menu-items my-6">
    <ul class="text-lg">
      <li>
        <a [class.active]="isActive('/instructor/courses')" routerLink="./courses">
          <svg-icon src="@assets/images/icons/course.svg" class="h-5 w-5"></svg-icon><span i18n="Instructor sidebar menu|Revenue Report">Course</span>
        </a>
      </li>
      <li>
        <a [class.active]="isActive('/instructor/assignments/overview') || isActiveChild('/instructor/assignments', 3)"
          routerLink="./assignments">
          <svg-icon src="@assets/images/icons/submissions.svg" class="h-5 w-5 *:bg-primary-500"></svg-icon>
          <span i18n="Instructor sidebar menu|Assignments">Assignments</span>
        </a>
      </li>
      <li>
        <a [class.active]="isActive('/instructor/question-answer') || isActiveChild('/instructor/question-answer', 3)"
          routerLink="./question-answer">
          <svg-icon src="@assets/images/icons/question-mark-circle-solid.svg" class="h-5 w-5"></svg-icon>
          <span i18n="Instructor sidebar menu|Q&A">Q&A</span>
        </a>
      </li>
      <!-- <li>
        <a [class.active]="isActive('/instructor/messages')" routerLink="./messages">
          <svg-icon src="@assets/images/icons/envelope-solid.svg" class="h-5 w-5"></svg-icon><span
            i18n="Instructor sidebar menu|Messages">Messages</span>
        </a>
      </li> -->
      <li>
        <a [class.active]="isActive('/instructor/reviews') || isActiveChild('/instructor/reviews', 3)"
          routerLink="./reviews">
          <svg-icon src="@assets/images/icons/chat-bubble-solid.svg" class="h-5 w-5"></svg-icon><span
            i18n="Instructor sidebar menu|Reviews">Reviews</span>
        </a>
      </li>
      <!-- <li>
        <a [class.active]="isActive('/instructor/reports') || isActiveChild('/instructor/reports', 3)"
          routerLink="./reports/total-revenue">
          <svg-icon src="@assets/images/icons/chart-pie-solid.svg" class="h-5 w-5"></svg-icon><span
            i18n="Instructor sidebar menu|Revenue Report">Revenue Report</span>
        </a>
      </li> -->
      <!-- <li>
        <a [class.active]="isActive('/instructor/announcement') || isActiveChild('/instructor/announcement', 3)"
          routerLink="./announcement">
          <svg-icon src="@assets/images/icons/megaphone-solid.svg" class="h-5 w-5"></svg-icon><span
            i18n="Instructor sidebar menu|Announcement">Announcement</span>
        </a>
      </li> -->
      <li>
        <a [class.active]="isActive('/instructor/payout') || isActiveChild('/instructor/payout', 3)"
          routerLink="./payout/overview">
          <svg-icon src="@assets/images/icons/credit-card.svg" class="h-5 w-5"></svg-icon><span i18n="Instructor sidebar menu|Payout">Payout</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="footing mt-auto w-full px-6 text-center">
    <a routerLink="/student">
      <button class="btn btn-white w-full">
        <span i18n="Instructor sidebar menu|Switch to Student">Switch to Student</span>
      </button>
    </a>
  </div>
</div>
