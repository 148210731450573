<div class="relative min-h-[300px]">
  <div *ngIf="isLoadingSource; else loadedSource" class="w-full h-full absolute flex items-center justify-center">
    <div [thkLibProcessing]="isLoadingSource" [size]="'lg'" variant="primary"></div>
  </div>
  <ng-template #loadedSource>
    <app-thk-video-player 
      #video
      (event)="videoEvent($event)" 
      [sources$]="sources$"
      [settingsButton]="true"
      [relatedMenu]="false"
      [relatedVideos]="[]"
      [qualityMenu]="true"
      [buttonRewind]="true"
      [theaterButton]="true"
      [videoTimelinePosition]="videoTimelinePosition"
      [lectureVideoCaptions]="lectureVideoCaptions">
    </app-thk-video-player>
    <!-- <video id="my-video" class="vjs-matrix video-js" controls preload="auto" data-setup="{}"></video> -->
    <div class="navigation text-white">
      <div class="nav-item prev absolute left-0 z-50" *ngIf="!hidePrev">
        <div class="flex cursor-pointer items-center gap-2" (click)="emitEvent('prev', { subsectionId: data?.section })">
          <div class="bg-slate-900 px-1 py-1.5">
            <svg-icon src="@assets/images/icons/chevron-left.svg" class="h-7 w-7"></svg-icon>
          </div>
          <div class="nav-label rounded-[4px] bg-slate-900 px-3 py-2">{{ prevData.title }}</div>
        </div>
      </div>
      <div class="nav-item next absolute right-0 z-50" *ngIf="!hideNext">
        <div class="flex cursor-pointer items-center gap-2" (click)="emitEvent('next', { subsectionId: data?.section })">
          <div class="nav-label rounded-[4px] bg-slate-900 px-3 py-2">{{ nextData.title }}</div>
          <div class="bg-slate-900 px-1 py-1.5">
            <svg-icon src="@assets/images/icons/chevron-right.svg" class="h-7 w-7"></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="custom-end">
      <h1>{{counter}}</h1>
      <p i18n>Thank you for watching</p>
      <button (click)="onPlay()" tooltip="Play Again">
        <svg-icon src="@assets/images/icons/reload.svg" class="h-20 w-20"></svg-icon>
      </button>
    </div>
  </ng-template>
</div>