import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import {
  Add_Remove_Cart,
  CartItems,
  CartSummary,
  CommonCartService,
  Coupon,
  CourseV2,
  CredentialsService,
  LocalStorage,
  PartialEntity,
} from 'thkee-common';
import { selectAuthCart } from '../auth';
import { CartState, cartKey, initialCartState } from './cart-state.model';
import { CartActions } from './cart.actions';

let AVAILABLE_CREDIT = 100;
let COUPONS: Coupon[] = [
  {
    code: 'sample50',
    amount: 50,
  },
  {
    code: 'sample20',
    amount: 20,
  },
  {
    code: 'sample70',
    amount: 70,
  },
];

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _cart: CartState = initialCartState;
  cartId!: string;
  courseIds: string[] = [];
  mainCartKey = this.credentialsService.isLogged() ? cartKey : cartKey + '_guest';

  constructor(
    private comCartService: CommonCartService,
    private credentialsService: CredentialsService,
    private store: Store,
    private router: Router
  ) {
    const savedCart = localStorage.getItem(this.mainCartKey);
    if (savedCart) {
      this._cart = JSON.parse(savedCart);
    }
  }

  checkKey() {
    this.mainCartKey = this.credentialsService.isLogged() ? cartKey : cartKey + '_guest';
  }

  getCart() {
    return of(this._cart);
  }

  get cart(): CartState {
    return this._cart;
  }

  hasCart(): boolean {
    return !!this._cart;
  }

  initUserCart() {
    this.checkKey();
    this.setCart(initialCartState, this.mainCartKey);
    return of(initialCartState);
  }

  loadCart() {
    let cartData = this._cart ? this._cart : initialCartState;
    if (this.credentialsService.isLogged()) {
      this.initUserCart();
      this.store
        .select(selectAuthCart)
        .pipe(untilDestroyed(this))
        .subscribe((cart) => {
          let courseIds: any = [];
          let cartId: any = 0;
          if (cart?.id) {
            if (cart.items) {
              courseIds = _.map(cart.items, 'product');
            }
            cartId = String(cart.id);
          }
          this.courseIds = courseIds;
        });

      cartData = {
        ...cartData,
        cartId: this.cartId,
        courses: this.courseIds,
        coursesData: [],
      };
    } else {
      // if (cartData.guestData) {
      //   cartData = {
      //     ...cartData.guestData,
      //   };
      // }
    }

    // TODO: Fetch real available credits here from API
    if (AVAILABLE_CREDIT) {
      cartData = {
        ...cartData,
        availableCredit: AVAILABLE_CREDIT,
      };
    }

    this.setCart(cartData);
    return of(cartData);
  }

  loadItems(): Observable<any> {
    if (this.credentialsService.isLogged()) {
      return this.comCartService.getCartItems().pipe(
        map((data: CartItems) => {
          let cartData = this._cart ? this._cart : initialCartState;
          const items = data.items;
          const courseIds = _.map(items, 'id');
          const courseCodes = _.map(items, 'code');

          const newData = {
            ...cartData,
            courses: courseIds,
            coursesData: items,
          };
          this.setCart(newData);
          this.mergedGuestCart();
          if (courseCodes.length > 0) {
            this.cartSummary(courseCodes).subscribe();
          }
          return items;
        }),
        catchError((error) => {
          return error;
        })
      );
    } else {
      const guestToken = this.getGuestToken();
      return this.comCartService.getGuestCartItems(guestToken).pipe(
        map((data: CartItems) => {
          let cartData = this._cart ? this._cart : initialCartState;
          const items = data.items;
          const courseIds = _.map(items, 'id');
          const courseCodes = _.map(items, 'code');

          const newData = {
            ...cartData,
            courses: courseIds,
            coursesData: items,
          };
          this.setCart(newData);
          if (courseCodes.length > 0) {
            this.cartSummary(courseCodes).subscribe();
          }
          return items;
        }),
        catchError((error) => {
          return error;
        })
      );
    }
  }

  addToCart(courseData: PartialEntity<CourseV2>): Observable<Add_Remove_Cart | any> {
    const item = {
      id: courseData.id,
      course_id: courseData.course_id,
      name: courseData.title,
      code: courseData.code,
      price: courseData?.priceDetails?.price?.amount || 0,
      sale: 0,
      image_url: courseData.image_url,
      sale_duration: '',
      author: courseData.user.fullname,
      rate: courseData.total_rate || 0,
      review: courseData.total_reviews || 0,
      star: Math.round(courseData.total_rate ? courseData.total_rate : 0),
      favorite: courseData.favorite,
      price_string: courseData?.priceDetails?.price?.price_string,
      currency: courseData?.priceDetails?.price?.currency,
    };

    if (this._cart) {
      this._cart = {
        ...this._cart,
        courses: [...this._cart.courses, item.id],
        coursesData: [...this.cart.coursesData, item],
      };
      this.setCart(this._cart);
    }
    const payload = {
      product: item.id,
    };
    const courseCodes = _.map(this.cart.coursesData, 'code');
    if (this.credentialsService.isLogged()) {
      this.comCartService
        .addToCart(payload)
        .pipe(
          tap(() => {
            this.cartSummary(courseCodes).subscribe();
          }),
          catchError((error) => {
            return of(null);
          })
        )
        .subscribe();
      return of(this._cart);
    }
    const guestToken = this.getGuestToken();
    this.comCartService
      .addToGuestCart(payload, guestToken)
      .pipe(
        tap(() => {
          this.cartSummary(courseCodes).subscribe();
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe();
    return of(this._cart);
  }

  removeToCart(id: string) {
    if (this._cart) {
      this._cart = {
        ...this._cart,
        courses: _.without(this._cart.courses, id),
        coursesData: _.filter(this._cart.coursesData, (o) => {
          return id != o.id;
        }),
      };
      this.setCart(this._cart);
    }
    const payload = {
      product: id,
    };
    // if (this._cart.coursesData.length > 0) {
    const courseCodes = _.map(this._cart.coursesData, 'code');
    this.cartSummary(courseCodes).subscribe();
    // }

    if (this.credentialsService.isLogged()) {
      this.comCartService.removeToCart(payload).subscribe();
    } else {
      const guestToken = this.getGuestToken();
      this.comCartService.removeToGuestCart(payload, guestToken).subscribe();
    }
    return of(this._cart);
  }

  // Guest token managed
  getGuestToken() {
    return JSON.stringify(LocalStorage.getObject<string>('guest_cart_token'));
  }

  removeGuestToken() {
    if (this.getGuestToken()) {
      LocalStorage.removeStorageItem('guest_cart_token');
    }
  }

  mergedGuestCart() {
    const guestToken = this.getGuestToken();
    if (guestToken) {
      this.comCartService.mergedGuestCart(guestToken).subscribe({
        next: (res) => {
          this.removeGuestToken();
          if (LocalStorage.getObject<string>('cart_guest')) {
            LocalStorage.removeStorageItem('cart_guest');
          }
          if (this.router.url === '/cart') {
            window.location.replace('/cart');
          }
        },
      });
    }
  }

  private cartSummary(courseCodes: Array<string>) {
    // const courseCodes = _.map(courseData, 'code');
    const summaryPayload = {
      // promo_code: 'XYZ',
      payables: courseCodes,
    };

    if (this.credentialsService.isLogged()) {
      return this.comCartService.getCartSummary(summaryPayload).pipe(
        map((data: CartSummary) => {
          this._cart = {
            ...this._cart,
            subtotal: Math.max(0, data.subtotal),
            total: Math.max(0, data.final_total_amount),
            availableCredit: 0,
            currency: data.currency,
            symbol: data.symbol,
            total_discount_amount: data.total_discount_amount,
            total_discount_percentage: data.total_discount_percentage,
          };
          this.setCart(this._cart);
          this.store.dispatch(CartActions.updateCartSummary({ summary: data }));
          return data;
        })
      );
    } else {
      const guestToken = this.getGuestToken();
      return this.comCartService.getGuestCartSummary(summaryPayload, guestToken).pipe(
        map((data: CartSummary) => {
          this._cart = {
            ...this._cart,
            subtotal: Math.max(0, data.subtotal),
            total: Math.max(0, data.final_total_amount),
            availableCredit: 0,
            currency: data.currency,
            symbol: data.symbol,
            total_discount_amount: data.total_discount_amount,
            total_discount_percentage: data.total_discount_percentage,
          };
          this.setCart(this._cart);
          this.store.dispatch(CartActions.updateCartSummary({ summary: data }));
          return data;
        })
      );
    }
  }

  applyCredit(credit: number) {
    if (this._cart) {
      let newavailableCredit = AVAILABLE_CREDIT - credit;
      this._cart = {
        ...this._cart,
        credit: credit,
        availableCredit: newavailableCredit,
      };
      this.setCart(this._cart);
    }
    return of(this._cart);
  }

  validateCoupon(code: string) {
    // return this.comCartService.validateCoupon(code);
    const isExist = _.find(COUPONS, { code: code });
    if (isExist) {
      return _.find(COUPONS, { code: code });
    } else {
      // of(errorActions.clientError({ section:'coupon', status: 400, error: ['Coupon not found.'] }))
      return false;
    }
  }

  applyCoupon(code: string) {
    // this.comCartService.validateCoupon(code).subscribe((data) => {});
    if (this._cart) {
      let findCoupon = this.validateCoupon(code);
      if (findCoupon) {
        this._cart = {
          ...this._cart,
          coupon: [...this._cart.coupon, findCoupon],
        };
        this.setCart(this._cart);
      } else {
        return throwError(() => 'Coupon not found');
      }
    }
    return of(this._cart);
  }

  setCart(cartData?: CartState | null, key?: string) {
    this._cart = cartData ? cartData : initialCartState;
    let currentKey = key ? key : this.mainCartKey;
    if (cartData) {
      const storage = localStorage;
      storage.setItem(currentKey, JSON.stringify(cartData));
    } else {
      localStorage.removeItem(currentKey);
    }
  }

  computeTotal() {
    if (this.cart?.coursesData) {
      this._cart = {
        ...this._cart,
      };
      this.setCart(this._cart);
    }
    return of(this._cart);
  }

  getCoursePrices(ids: string[]): Observable<any> {
    return this.comCartService.getPrices(ids).pipe(
      map((prices) => {
        if (prices.courses) {
          const transformedData = _.mapValues(prices.courses, (course) => ({
            price: course.price.amount,
            price_string: course.price.price_string,
            discount_price: course.discount_price,
            currency: course.price.currency,
          }));
          return transformedData;
        }
        return prices;
      }, {})
    );
  }
}
