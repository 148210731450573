export const PREFIX = '/api/v2';
// Authentication
export const API_USERS_LOGIN = `${PREFIX}/users/auth/login/`;
export const API_REFRESH_TOKEN = `${PREFIX}/users/auth/refresh/`;

// Account
export const API_USERS_UPDATE_ME = `${PREFIX}/users/profile/me/update/`;
export const API_USERS_UPDATE_ME_EMAIL = `${PREFIX}/users/auth/change-email/`;
export const API_USERS_LOGOUT = `${PREFIX}/dj-rest-auth/logout/`;
export const API_USERS_SIGNUP = `${PREFIX}/users/signup/`;
export const API_USERS_DETAIL = `${PREFIX}/users/profile/me/`;
export const API_USERS_BECOMEINSTRUCTOR = `${PREFIX}/users/instructors/become_instructor/`;
export const API_USERS_VERIFY_LINK_TOKEN = `${PREFIX}/users/auth/email-verification/`;
export const API_USERS_AUTH_INFO = `${PREFIX}/users/profile/authentication/`;
// TODO forgot password
export const API_USERS_UPDATE_PASSWORD = `${PREFIX}/users/change-password/`;
export const API_USERS_FORGOT_PASSWORD = `${PREFIX}/users/auth/forgot-password/`;
export const API_USERS_RESET_PASSWORD = `${PREFIX}/users/auth/reset-password/`;
export const API_RESET_PASSWORD_GET_USER = `${PREFIX}/users/auth/verify-reset-token/`;
export const API_REVERIFY_ACCOUNT = `${PREFIX}/users/auth/resend-email-for-verification/`;

// Common API
export const API_COMMON_INSTRUCTOR_GROUP = `${PREFIX}/users/instructor-groups/`;
export const API_COMMON_SHARE_PRICING = `${PREFIX}/common/share-pricing/`;

// Course
export const API_COURSES_DATA_GET = `${PREFIX}/courses/instructor-course/<COURSE_ID>/hydrate/`;
export const API_COURSES_DATA_GET_PUBLIC = `${PREFIX}/courses/<COURSE_ID>/hydrate-public/`;
export const API_COURSES_DETAILS_GET_PUBLIC = `${PREFIX}/courses/published/<COURSE_ID>/`;
export const API_COURSES_GET = `${PREFIX}/courses/instructor-course/<COURSE_ID>/`;
export const API_COURSES_UPDATE = `${PREFIX}/courses/instructor-course/<COURSE_ID>/`;
export const API_GET_COURSE_REFERRAL_CODE = `${PREFIX}/courses/instructor-refferal/get-referral-link/`;
export const API_COURSES_CATEGORIES = `${PREFIX}/courses/categories/`;
export const API_COURSES_TOPICS = `${PREFIX}/courses/topics/`;
export const API_COURSES_PRICING = `${PREFIX}/common/pricing`;

// /courses/instructor-course/{id}/
// Course QnA
export const API_COURSES_GET_QNA = `${PREFIX}/courses/<COURSE_ID>/qnas/<QNA_ID>/`;
export const API_COURSES_SECTION_QNA = `${PREFIX}/courses/<COURSE_ID>/qnas/subsection/<SECTION_ID>/`;
export const API_COURSES_QNA_ALLREPLY = `${PREFIX}/courses/<COURSE_ID>/qnas/all-qna-reply/<QNA_ID>/`;
export const API_COURSES_QNA_ADDREPLY = `${PREFIX}/courses/qna-replies/<QNA_ID>/`;

// Course Assignment Submission
export const API_COURSES_ASSIGNMENT_START = `${PREFIX}/courses/<COURSE_ID>/start-assignment/<ASSIGNMENT_ID>/`;
export const API_COURSES_ASSIGNMENT = `${PREFIX}/courses/<COURSE_ID>/assignment-submission/<ASSIGNMENT_ID>/`;
// Course Quiz Submission
export const API_COURSES_QUIZ = `${PREFIX}/courses/<COURSE_ID>/quizzes/<QUIZ_ID>/answers/`;

export const API_COURSES_TOPCATEGORIES = `${PREFIX}/courses/categories/top/`;
// PUBLIC Course Endpoints
export const API_COURSES_PUBLIC_TOPICS = `${PREFIX}/courses/public-topics/`;
export const API_COURSES_PUBLIC_SUBCATEGORIES = `${PREFIX}/courses/public-subcategories/`;
export const API_PUBLISHED_COURSES = `${PREFIX}/courses/published/`;
export const API_CONSTANTS_PUBLIC = `${PREFIX}/common/constants/public/`;
export const API_CONSTANTS_LANGUAGES = `${PREFIX}/common/constants/languages/`;
export const API_CONSTANTS_SKILL_LEVELS = `${PREFIX}/common/constants/skill_levels/`;
export const API_CONSTANTS_COUNTRIES = `${PREFIX}/common/constants/countries/`;
export const API_HOME_TOP_CATEGORIES = `${PREFIX}/courses/public-category-counts/`;
export const API_MOST_POPULAR_COURSES = `${PREFIX}/courses/most-popular-courses/`;

export const API_CONSTANTS_CURRENCIES = `${PREFIX}/common/currencies/`;
export const API_CONSTANTS_COURSE_PRICING = `${PREFIX}/common/pricing-tiers/`;

// Course Curriculum
export const API_COURSES_SECTION_GET = `${PREFIX}/courses/<COURSE_ID>/sections/`;
export const API_COURSES_SECTION_UPSERT = `${PREFIX}/courses/sections/`;
export const API_COURSES_SECTION_DELETE = `${PREFIX}/courses/sections/<SECTION_ID>/`;

export const API_COURSES_SUBSECTION_GET = `${PREFIX}/courses/<COURSE_ID>/sections/<SECTION_ID>/subsections/`;
export const API_COURSES_SUBSECTION_UPSERT = `${PREFIX}/courses/subsections/`;
export const API_COURSES_SUBSECTION_DELETE = `${PREFIX}/courses/subsections/<SUBSECTION_ID>/`;

export const API_COURSES_QUIZ_UPSERT = `${PREFIX}/courses/quizzes/`;
export const API_COURSES_QUIZ_QUESTION_UPSERT = `${PREFIX}/courses/quiz-questions/`;
export const API_COURSES_QUIZ_QUESTION_DELETE = `${PREFIX}/courses/quiz-questions/<ID>/`;
export const API_COURSES_QUIZ_ANSWER_UPSERT = `${PREFIX}/courses/quiz-answers/`;
export const API_COURSES_QUIZ_ANSWER_DELETE = `${PREFIX}/courses/quiz-answers/<ID>/`;

export const API_COURSES_ASSIGNMENT_UPSERT = `${PREFIX}/courses/assignments/`;
export const API_COURSES_ASSIGNMENT_QUESTION_UPSERT = `${PREFIX}/courses/assignment-questions/`;
export const API_COURSES_ASSIGNMENT_QUESTION_DELETE = `${PREFIX}/courses/assignment-questions/<ID>/`;

export const API_COURSES_LECTURE_UPSERT = `${PREFIX}/courses/lectures/`;
export const API_COURSES_LECTURE_RESOURCE_UPSERT = `${PREFIX}/courses/<LECTURE_PK>/lecture-resources/`;
export const API_COURSES_LECTURE_RESOURCE_DELETE = `${PREFIX}/courses/lecture-resources/<ID>`;

// /courses/sections/{course_id}/position-update/
export const API_POST_COURSES_SECTION_POSITION_UPDATE = `${PREFIX}/courses/sections/<COURSE_ID>/position-update/`;
// /courses/subsections/{section_id}/position-update/
export const API_POST_COURSES_SUBSECTION_POSITION_UPDATE = `${PREFIX}/courses/subsections/<SECTION_ID>/position-update/`;

// Course Intended
export const COURSE_INTENDED_GET = `${PREFIX}/courses/<COURSE_ID>/<TYPE>`;
export const COURSE_INTENDED_CREATE = `${PREFIX}/courses/<COURSE_ID>/<TYPE>`;
export const COURSE_INTENDED_UPDATE = `${PREFIX}/courses/<COURSE_ID>/<TYPE>/<ID>`;
export const COURSE_INTENDED_DELETE = `${PREFIX}/courses/<COURSE_ID>/<TYPE>/<ID>`;

// Project
export const API_PROJECT_GET = `${PREFIX}/courses/projects/<PROJECT_ID>/`;

// Course State
export const API_COURSES_DRAFT = `${PREFIX}/courses/projects/<PROJECT_ID>/to_state_draft/`;
export const API_COURSES_IN_REVIEW = `${PREFIX}/courses/projects/<PROJECT_ID>/to_state_in_review/`;
export const API_COURSES_REVIEW_OR_PUBLISHED = `${PREFIX}/courses/projects/<PROJECT_ID>/to_state_review_or_published/`;
export const API_COURSES_PUBLISHED = `${PREFIX}/courses/projects/<PROJECT_ID>/to_state_published/`;
export const API_COURSES_REJECTED = `${PREFIX}/courses/projects/<PROJECT_ID>/to_state_rejected/`;
export const API_COURSES_ARCHIVED = `${PREFIX}/courses/projects/<PROJECT_ID>/to_state_archived/`;
export const API_COURSES_WITHDRAW = `${PREFIX}/courses/projects/<PROJECT_ID>/withdraw_submission/`;

// Annotations
export const API_COURSES_ANNOTATION_UPSERT = `${PREFIX}/courses/annotations/`;
export const API_COURSES_ANNOTATIONS_BY_PROJECT_GET = `${PREFIX}/courses/annotations/get_annotations_by_project/`;

// Cart
export const API_GET_CART = `${PREFIX}/shopping-carts/my-cart/retrieve-cart/`;
export const API_CART_ADD = `${PREFIX}/shopping-carts/my-cart/add-to-cart/`;
export const API_CART_REMOVE = `${PREFIX}/shopping-carts/my-cart/remove-from-cart/`;
export const API_CART_RECENTLY_WISHLISTED = `${PREFIX}/courses/recently-wishlisted/`;
// Cart guest
export const API_GET_GUEST_CART = `${PREFIX}/shopping-carts/guest-cart/retrieve-cart/`;
export const API_ADD_GUEST_CART = `${PREFIX}/shopping-carts/guest-cart/add-to-cart/`;
export const API_REMOVE_GUEST_CART = `${PREFIX}/shopping-carts/guest-cart/remove-from-cart/`;
export const API_MERGED_GUEST_CART = `${PREFIX}/shopping-carts/guest-cart/merge-cart/`;
export const API_RECOMMENDED_COURSES_CART = `${PREFIX}/shopping-carts/my-cart/recommended-courses/`;
export const API_CART_SUMMERY = `${PREFIX}/shopping-carts/checkout/payment-summary/`;
export const API_CART_GUEST_SUMMERY = `${PREFIX}/shopping-carts/guest-cart/payment-summary/`;

// Checkout
export const API_CHECKOUT_INIT = `${PREFIX}/shopping-carts/checkout/initiate/`;
export const API_CHECKOUT_ORDER = `${PREFIX}/shopping-carts/checkout/<ID>/order/`;
export const API_CHECKOUT_PAYPAL_CALLBACK = `${PREFIX}/shopping-carts/checkout/callback/?order_token=<TOKEN>`;
export const API_GET_SUPPORTED_CURRENCY = `${PREFIX}/common/currency-support-by-payment-method`;

// Payment
export const API_INSTRUCTOR_PAYOUT_TYPE_UPDATE = (userId: number) =>
  `${PREFIX}/users/instructor-payouts/${userId}/update-payout-type/`;
export const API_INSTRUCTOR_PAYOUT_OVERVIEW = `${PREFIX}/users/instructor-payouts/overview/`;
export const API_INSTRUCTOR_PAYOUT_METHODS = `${PREFIX}/users/instructor-payouts/payment-methods/`;
export const API_INSTRUCTOR_PAYOUT_ACTIVATE_METHOD = (methodId: number) =>
  `${PREFIX}/users/instructor-payouts/${methodId}/toggle-active-payment/`;
export const API_INSTRUCTOR_PAYOUT_DIRECTLY_BANK = (methodId: number) =>
  `${PREFIX}/users/instructor-payouts/${methodId}/update-bank-details/`;
export const API_INSTRUCTOR_PAYOUT_CONNECT = (methodId: number) =>
  `${PREFIX}/users/instructor-payouts/${methodId}/connect-payment/`;
export const API_INSTRUCTOR_PAYOUT_CONNECT_CALLBACK = (methodId: number) =>
  `${PREFIX}/users/instructor-payouts/${methodId}/connect-callback/`;
export const API_INSTRUCTOR_PAYOUT_ACTIVITIES = `${PREFIX}/users/instructor-payouts/payout-activities/`;
export const API_INSTRUCTOR_PAYOUT_TRANSACTIONS = (payoutId: number) =>
  `${PREFIX}/users/instructor-payouts/${payoutId}/transactions/`;
export const API_INSTRUCTOR_PAYOUT_TRANSACTION_DETAILS = (transId: number) =>
  `${PREFIX}/users/instructor-payouts/${transId}/transaction-details/`;
export const API_INSTRUCTOR_PAYOUT_WITHDRAW_DETAILS = (payoutId: number) =>
  `${PREFIX}/users/instructor-payouts/${payoutId}/get-withdraw-details/`;
export const API_INSTRUCTOR_PAYOUT_REQUEST_WITHDRAW = (payoutId: number) =>
  `${PREFIX}/users/instructor-payouts/${payoutId}/create-payout-request/`;
export const API_INSTRUCTOR_PAYOUT_REQUESTS = `${PREFIX}/users/instructor-payouts/payout-requests/`;
export const API_INSTRUCTOR_PAYOUT_REQUEST = `${PREFIX}/users/instructor-payouts/last-payout-summary/`;
export const API_INSTRUCTOR_PAYOUT_REQUEST_TRANSACTIONS = (payoutRequestId: number) =>
  `${PREFIX}/users/instructor-payouts/payout-requests/${payoutRequestId}/transactions/`;

// Admin payout request
export const API_ADMIN_PAYOUT_REQUESTS = `${PREFIX}/users/admin-payouts/payout-requests/`;
export const API_ADMIN_PAYOUT_RETRY_REQUESTS = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/${requestId}/retry/`;
export const API_ADMIN_PAYOUT_RETRY_REQUEST_DETAILS = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/${requestId}/get-detail-for-refresh-retry/`;
export const API_ADMIN_PAYOUT_MANY_REQUESTS_SUMMARY = `${PREFIX}/users/admin-payouts/payout-requests/get-action-confirmation/`;
export const API_ADMIN_PAYOUT_ALL_REQUESTS_SUMMARY = `${PREFIX}/users/admin-payouts/payout-requests/get-action-confirmation-for-all/`;
export const API_ADMIN_PAYOUT_REQUEST_TRANSACTIONS = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/transactions/`;
export const API_ADMIN_PAYOUT_REQUEST_TRANSACTION_ANALYTIC = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/approval-confirmation/`;

export const API_ADMIN_PAYOUT_REQUEST_TRANSACTION_DETAIL = (transactionId: number) =>
  `${PREFIX}/users/admin-payouts/${transactionId}/get-details-for-hold-transaction/`;
export const API_ADMIN_PAYOUT_HOLD_REQUEST_TRANSACTION = (transactionId: number) =>
  `${PREFIX}/users/admin-payouts/${transactionId}/hold-transaction/`;
export const API_ADMIN_PAYOUT_UNHOLD_REQUEST_TRANSACTION = (requestId: number, transactionId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/transactions/${transactionId}/undo/`;
export const API_ADMIN_PAYOUT_REQUESTS_SUMMARY = `${PREFIX}/users/admin-payouts/payout-requests/summary/`;
export const API_ADMIN_PAYOUT_APPROVE = `${PREFIX}/users/admin-payouts/payout-requests/approve/`;
export const API_ADMIN_PAYOUT_APPROVE_ALL = `${PREFIX}/users/admin-payouts/payout-requests/approve-all/`;
export const API_ADMIN_PAYOUT_DEACTIVATE = `${PREFIX}/users/admin-payouts/payout-requests/deactivate/`;
export const API_ADMIN_PAYOUT_DEACTIVATE_ALL = `${PREFIX}/users/admin-payouts/payout-requests/deactivate-all/`;
export const API_ADMIN_PAYOUT_REQUEST_DETAIL_SUMMARY = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/transactions/summary/`;
export const API_ADMIN_PAYOUT_REQUEST_HISTORY = (payout_request_id: number) =>
  `${PREFIX}/users/admin-payouts/payout-request/${payout_request_id}/history/`;
export const API_ADMIN_PAYOUT_TRANSACTION_APPROVE = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/transactions/approve/`;
export const API_ADMIN_PAYOUT_TRANSACTION_APPROVE_ALL = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/transactions/approve-all/`;
export const API_ADMIN_PAYOUT_HOLD_TRANSACTION = (transId: number) =>
  `${PREFIX}/users/admin-payouts/${transId}/hold-transaction/`;
export const API_ADMIN_PAYOUT_HOLD_TRANSACTIONS_SUMMARY = `${PREFIX}/users/admin-payouts/hold-transactions/summary/`;
export const API_ADMIN_PAYOUT_HOLD_MANY_TRANSACTIONS = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/hold-transactions/`;
export const API_ADMIN_PAYOUT_HOLD_ALL_TRANSACTIONS = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/payout-requests/${requestId}/hold-transactions-all/`;
export const API_ADMIN_PAYOUT_INACTIVE = `${PREFIX}/users/admin-payouts/inactive-payouts/`;
export const API_ADMIN_PAYOUT_INACTIVE_TRANSACTIONS = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/inactive-payouts/${requestId}/transactions/`;
export const API_ADMIN_PAYOUT_INACTIVE_ACTIVATE = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/inactive-payouts/${requestId}/activate/`;
export const API_ADMIN_PAYOUT_INACTIVE_ACTIVATE_PAYOUT = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/inactive-payouts/${requestId}/activate-payout/`;
export const API_ADMIN_PAYOUT_INACTIVE_DETAILS_FOR_PAID = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/inactive-payouts/${requestId}/details-for-marking-as-paid/`;
export const API_ADMIN_PAYOUT_MARK_AS_PAID = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/${requestId}/mark-as-paid/`;
export const API_ADMIN_PAYOUT_LIST_HOLD_TRANSACTIONS = `${PREFIX}/users/admin-payouts/hold-transactions/`;
export const API_ADMIN_PAYOUT_UNHOLD_TRANSACTION = (id: number) =>
  `${PREFIX}/users/admin-payouts/${id}/unhold-transaction/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS = `${PREFIX}/users/admin-payouts/ready-requests/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_DEACTIVATE_ALL = `${PREFIX}/users/admin-payouts/ready-requests/deactivate-all/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_DEACTIVATE = `${PREFIX}/users/admin-payouts/ready-requests/deactivate/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_RELEASE_ALL = `${PREFIX}/users/admin-payouts/ready-requests/release-all/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_RELEASE = `${PREFIX}/users/admin-payouts/ready-requests/release/`;

export const API_ADMIN_PAYOUT_PAID_REQUESTS = `${PREFIX}/users/admin-payouts/paid-payouts/`;
export const API_ADMIN_PAYOUT_PAID_REQUESTS_SUMMARY = `${PREFIX}/users/admin-payouts/paid-payouts/summary/`;

export const API_ADMIN_PAYOUT_FAILED_REQUESTS = `${PREFIX}/users/admin-payouts/failed-payouts/`;

export const API_ADMIN_PAYOUT_READY_REQUESTS_SUMMARY = `${PREFIX}/users/admin-payouts/ready-requests/summary/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_MARK_AS_FAILED = (requestId: number) =>
  `${PREFIX}/users/admin-payouts/ready-requests/${requestId}/mark-as-failed/`;
export const API_ADMIN_PAYOUT_READY_REQUEST_DETAILS = (payout_request_id: number) =>
  `${PREFIX}/users/admin-payouts/ready-requests/${payout_request_id}/details/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_TRANSACTIONS = (payout_request_id: number) =>
  `${PREFIX}/users/admin-payouts/ready-requests/${payout_request_id}/transactions/`;
export const API_ADMIN_PAYOUT_READY_REQUESTS_CONFIRM_HOLD = (payout_request_id: number, transaction_id: number) =>
  `${PREFIX}/users/admin-payouts/ready-requests/${payout_request_id}/transactions/${transaction_id}/confirm-hold/`;
export const API_ADMIN_PAYOUT_INSTRUCTOR_BALANCES = `${PREFIX}/users/admin-payouts/instructor-balances/`;
export const API_ADMIN_PAYOUT_INSTRUCTOR_BALANCE_TRANSACTIONS = (instructor_id: number) =>
  `${PREFIX}/users/admin-payouts/instructor-balances/${instructor_id}/transactions/`;
export const API_ADMIN_PAYOUT_INSTRUCTOR_WITHDRAWS = (instructor_id: number) =>
  `${PREFIX}/users/admin-payouts/instructor-balances/${instructor_id}/recently-withdrawn/`;
export const API_ADMIN_PAYOUT_INSTRUCTOR_BALANCE_PAYOUTS = (instructor_id: number) =>
  `${PREFIX}/users/admin-payouts/instructor-balances/${instructor_id}/payout-requests/`;

export const API_GET_ADMIN_INACTIVATION_PAYOUT_SUMMARY = `${PREFIX}/users/admin-payouts/inactive-payouts/summary/`;
export const API_GET_ADMIN_FAILED_PAYOUT_SUMMARY = `${PREFIX}/users/admin-payouts/failed-payouts/summary/`;

// Payment
export const API_CHECKOUT_PAYMENT_STRATEGIES_LIST = `${PREFIX}/shopping-carts/payment-strategiest/list/`;
export const API_CHECKOUT_SAVE_CARD_LIST = `${PREFIX}/users/saved-cards/`;

export const API_CART_CHECKOUT = ``; // Deprecated
export const API_PAYMENT_VERIFY_TOKEN = ``; // Deprecated

// Pricing
export const API_PRICING_LIST = `${PREFIX}/common/pricing-tiers/`;
export const API_COUNTRY_LIST = `${PREFIX}/common/countries/`;
export const API_CURRENCY_LIST = `${PREFIX}/common/currencies/`;
export const API_GEO_LOCATION = `${PREFIX}/common/geo-locations/`;
export const API_GEO_PRICING = `${PREFIX}/common/geo-pricing/`;
export const API_GEO_PRICING_TIER = `${PREFIX}/common/geo-pricing-tiers/`;
export const API_CURRENCIES_LIVE_RATE = `${PREFIX}/common/currencies/live-rates/`;
export const API_CURRENCIES_ALL = `${PREFIX}/common/currencies/all/`;
export const API_TIRE_EXCHANGE_RATE = `${PREFIX}/common/pricing-tiers/<TIRE_ID>/get-tier-exchange-rates/`;

export const API_GEO_LIST = `${PREFIX}/common/geo-locations/`;
export const API_GEO_PRICING_TIERS = `${PREFIX}/common/geo-pricing-tiers/`;
export const API_EXCHANGE_RATES = `${PREFIX}/common/exchange-rates/`;

// Instructor assignments
export const API_INSTRUCTOR_ASSIGNMENTS_SUMMARY = `${PREFIX}/courses/instructor-assignments-summary/`;
export const API_INSTRUCTOR_ASSIGNMENTS_BY_COURSES = `${PREFIX}/courses/course-assignments/<COURSE_ID>`;
export const API_INSTRUCTOR_ASSIGNMENTS_GROUP_BY_DATE = `${PREFIX}/courses/students-assignments/<ASSIGNMENT_ID>/`;
export const API_INSTRUCTOR_ASSIGNMENTS_REVIEW_ANSWER = `${PREFIX}/courses/students-assignments-answers/<ASSIGNMENT_ID>/`;

// Instructor Q&A
export const API_INSTRUCTOR_QNA_GROUP = `${PREFIX}/courses/instructor-qna/`;
export const API_INSTRUCTOR_QNA_ALL = `${PREFIX}/courses/all-qnas/<COURSE_ID>/`;
export const API_INSTRUCTOR_REPORT_QNA = `${PREFIX}/courses/report-qna/<QNA_ID>/`;
export const API_INSTRUCTOR_REPORT_QNA_REPLY = `${PREFIX}/courses/report-qna-reply/<QNA_REPLY_ID>/`;
export const API_INSTRUCTOR_MARK_NOT_RELEVANT = `${PREFIX}/courses/mark-not-relevant/<QNA_ID>/`;
export const API_INSTRUCTOR_QNA_REPLY_ALL = `${PREFIX}/courses/all-qna-reply/<QNA_ID>/`;
export const API_INSTRUCTOR_QNA_REPLY = `${PREFIX}/courses/instructor-qna/<QNA_ID>/qna-reply/`;
export const API_INSTRUCTOR_QNA_REPLY_UPDATE = `${PREFIX}/courses/instructor-qna/<QNA_ID>/qna-reply/<REPLY_ID>/`;

// Instructor Reviews
export const API_INSTRUCTOR_COURSES_REVIEWS = `${PREFIX}/courses/instructor-course-reviews/`;
export const API_INSTRUCTOR_REVIEWS_BY_COURSE = `${PREFIX}/courses/all-reviews/<COURSE_ID>/`;
export const API_INSTRUCTOR_REPLIES = `${PREFIX}/courses/instructor-replies/`;
export const API_INSTRUCTOR_REPLIES_UPDATE = `${PREFIX}/courses/instructor-replies/<REVIEW_ID>/`;

// Course Details
export const API_COURSE_DETAILS_INSTRUCTOR_INFO = `${PREFIX}/courses/published/<SLUG>/instructor/`;
export const API_COURSE_DETAILS_COURSES_PRICE = `${PREFIX}/common/pricing`;
export const API_COURSE_DETAILS_COURSES_CONTENT = `${PREFIX}/courses/published/<SLUG>/sections`;
export const API_COURSE_DETAILS_COURSES_REVIEWS = `${PREFIX}/courses/published/<SLUG>/reviews`;
export const API_COURSE_DETAILS_COURSES_REVIEW_POST = `${PREFIX}/courses/<COURSE__PK>/reviews/`;
export const API_COURSE_DETAILS_COURSES_FAVORITE_TOGGLE = `${PREFIX}/shopping-carts/my-cart/toggle-favorite/`;

// Course Details Preview for instructor
export const API_COURSE_DETAILS_PREVIEW = `${PREFIX}/courses/preview-course/<SLUG>/`;
export const API_COURSE_DETAILS_INFO_INSTRUCTOR_PREVIEW = `${PREFIX}/courses/preview-course/<SLUG>/instructor/`;
export const API_COURSE_DETAILS_SECTION_PREVIEW = `${PREFIX}/courses/preview-course/<SLUG>/sections/`;
export const API_COURSE_DETAILS_REVIEW_PREVIEW = `${PREFIX}/courses/preview-course/<SLUG>/reviews/`;
export const API_GET_MY_CLASSES_OVERVIEW_PREVIEW = `${PREFIX}/courses/preview-learning-lab/lecture/<COURSE_ID>/`;
export const API_GET_CLASSES_CONTENT_PREVIEW = `${PREFIX}/courses/preview-learning-lab/<COURSE_ID>/content/`;
export const API_GET_CLASSES_ANNOUNCEMENT_PREVIEW = `${PREFIX}/courses/preview-learning-lab/<COURSE_ID>/announcements/`;
export const API_GET_CLASSES_REVIEW_PREVIEW = `${PREFIX}/courses/preview-learning-lab/<COURSE_ID>/reviews/`;

// Free course
export const API_POST_ZERO_PAYMENT = `${PREFIX}/shopping-carts/checkout/zero-payment/`;

// Category page
export const API_BEST_SALLER_COURSE = `${PREFIX}/courses/courses-bestseller/bestseller-course/`;

export enum ContentTypeApps {
  COURSES = 'courses',
}
export enum ContentTypeModels {
  COURSE = 'course',
  OBJECTIVE = 'courseobjective',
  LEARNER = 'courselearner',
  REQUIREMENT = 'courserequirement',
  SECTION = 'section',
  SUBSECTION = 'subsection',
  QUIZ_QUESTION = 'quizquestion',
  ASSIGNMENT_QUESTION = 'assignmentquestion',
  LECTURE = 'lecture',
  QUIZ = 'quiz',
  ASSIGNMENT = 'assignment',
}

// All Old prefixs
// export const API_ASSIGNMENTS = '/courses/api';
// export const API_PRICING_API = `/common/api`;
// export const API_SHOPPING_API = `/shopping-carts`;
// export const API_CHECKOUT = `/shopping-carts/checkout`;
// export const API_CART = `/shopping-carts/my-cart`;
// export const API_CONSTANTS = '/common/api/constants';

// Payments
export const API_GET_SAVED_CARDS = `${PREFIX}/users/saved-cards/`;

export const API_COURSES_QNA = `${PREFIX}/courses/<COURSE_ID>/qnas`;
export const API_COURSES = `${PREFIX}/courses`;

// Instructor
export const API_COURSES_INSTRUCTOR = `${PREFIX}/courses/instructor-course`; // instructor
export const API_GET_VIDEO_LIBRARY = `${PREFIX}/common/video-library/`; // instructor
// export const API_COMMON = `/common/api`;
export const API_USERS = '/dj-rest-auth';

// Admin
export const API_COURSES_ADMIN = `${PREFIX}/courses`;
export const API_COURSES_ADMIN_DATA_GET = `${PREFIX}/courses/<COURSE_ID>/hydrate/`;
export const API_GET_RECENTLY_UPDATED_COURSE = `${PREFIX}/courses/recently-updated/`;
export const API_GET_COMMERCE_TRANSACTION = `${PREFIX}/transactions/`;
export const API_GET_COMMERCE_TRANSACTION_DETAILS = `${PREFIX}/transactions/<TRANSACTION_ID>/`;
export const API_GET_COMMERCE_ORDER = `${PREFIX}/shopping-carts/orders/`;
export const API_GET_COMMERCE_ORDER_DETAILS = `${PREFIX}/shopping-carts/orders/<ORDER_ID>/`;
export const API_GET_COMMERCE_ORDER_DETAILS_ITEMS = `${PREFIX}/shopping-carts/orders/order-transactions/`;
export const API_GET_COMMERCE_REFUND = `${PREFIX}/transactions/refund-requests/`;
export const API_GET_COMMERCE_REFUND_DETAILS = `${PREFIX}/transactions/refund-requests/<ORDER_ID>/`;
export const API_POST_COMMERCE_REFUND_REQUEST_APPROVED = `${PREFIX}/transactions/refund-requests/<ORDER_ID>/approve/`;
export const API_POST_COMMERCE_FORCE_REFUND_REQUEST_APPROVED = `${PREFIX}/transactions/refund-requests/<ORDER_ID>/force-approve/`;
export const API_POST_COMMERCE_REJECT_REQUEST = `${PREFIX}/transactions/refund-requests/<ORDER_ID>/reject/`;

// Admin settings
export const API_GET_POST_ADMIN_CATEGORY = `${PREFIX}/courses/categories/`;
export const API_UPDATE_ADMIN_CATEGORY = `${PREFIX}/courses/categories/<CATEGORY_ID>/`;
export const API_DELETE_ADMIN_CATEGORY = `${PREFIX}/courses/categories/<CATEGORY_ID>/`;
export const API_GET_POST_ADMIN_SUBCATEGORY = `${PREFIX}/courses/subcategories/`;
export const API_UPDATE_DELETE_ADMIN_SUBCATEGORY = `${PREFIX}/courses/subcategories/<SUBCATEGORY_ID>/`;
export const API_GET_POST_ADMIN_TOPICS = `${PREFIX}/courses/topics/`;
export const API_PUT_PATCH_DELETE_ADMIN_TOPICS = `${PREFIX}/courses/topics/<TOPIC_ID>/`;

export const API_GET_ADMIN_COURSES_QNA = `${PREFIX}/courses/<PRODUCT_ID>/admin-qna/`;
export const API_GET_ADMIN_COURSES_QNA_REPLIES = `${PREFIX}/courses/<QNA_ID>/admin-all-qna-replies/`;
export const API_GET_ADMIN_COURSES_REVIEWS = `${PREFIX}/courses/<PRODUCT_ID>/admin-course-reviews/`;
export const API_DELETE_AND_UPDATE_ADMIN_COURSES_REVIEW = `${PREFIX}/courses/course-reviews/<REVIEW_ID>/`;
// export const API_UPDATE_ADMIN_COURSES_REVIEW = `${PREFIX}/courses/course-reviews/<REVIEW_ID>/`;
export const API_GET_ADMIN_COURSES_ACTIVITIES = `${PREFIX}/courses/<PRODUCT_ID>/admin-activities/`;
export const API_POST_ADMIN_COURSES_PRICING = `${PREFIX}/courses/admin-course/<PRODUCT_ID>/`;

// Admin user payout activation
export const API_GET_ADMIN_PAYOUT_ACTIVATION_SUMMARY = `${PREFIX}/users/admin-payouts/payouts-activation/summary/`;
export const API_GET_ADMIN_PAYOUT_ACTIVATION = `${PREFIX}/users/admin-payouts/payouts-activation/`;
export const API_GET_ADMIN_PAYOUT_PROFILE_DETAILS = `${PREFIX}/users/admin-payouts/<INSTRUCTOR_ID>/payout-profile-details/`;
export const API_GET_ADMIN_PAYOUT_HISTORY = `${PREFIX}/users/admin-payouts/<INSTRUCTOR_ID>/payout-method-history/`;
export const API_POST_ADMIN_APPROVED_ACCOUNT = `${PREFIX}/users/admin-payouts/bank-account/<PAYMENT_METHOD_ID>/approve/`;
export const API_POST_ADMIN_REJECT_ACCOUNT = `${PREFIX}/users/admin-payouts/bank-account/<PAYMENT_METHOD_ID>/reject/`;
export const API_POST_ADMIN_VERIFY_INSTRUCTOR_PROFILE = `${PREFIX}/users/admin-payouts/<INSTRUCTOR_ID>/verify-instructor-profile/`;
export const API_POST_ADMIN_REJECT_INSTRUCTOR_PROFILE = `${PREFIX}/users/admin-payouts/<INSTRUCTOR_ID>/reject-instructor-profile/`;
export const API_POST_ADMIN_DISABLE_PAYOUT = `${PREFIX}/users/admin-payouts/<INSTRUCTOR_ID>/disable-payout/`;

// My Classes
export const API_GET_MY_CLASSES = `${PREFIX}/users/my-classes/`;
export const API_GET_MY_WISHLIST = `${PREFIX}/shopping-carts/my-wishlist/`;
export const API_GET_CLASSES_CONTENT = `${PREFIX}/users/classes/<COURSE_ID>/content/`;
export const API_POST_CLASSES_PROGRESS = `${PREFIX}/users/classes/<COURSE_CODE>/record-progress/`;
export const API_GET_MY_CLASSES_OVERVIEW = `${PREFIX}/users/my-classes/<COURSE_ID>/`;
export const API_POST_QUIZ_ANSWER = `${PREFIX}/courses/<COURSE_ID>/quizzes/<QUIZ_ID>/answers/`;
export const API_GET_CLASSES_ANNOUNCEMENT = `${PREFIX}/courses/<COURSE_ID>/announcements/`;
export const API_GET_CLASSES_TOGGLE_ARHHIVE = `${PREFIX}/users/classes/<COURSE_ID>/toggle-archive/`;
export const API_GET_CLASSES_VIDEO_POSITION = `${PREFIX}/users/classes/<COURSE_CODE>/progress-of/<SUBSECTION_ID>`;
export const API_GET_CLASSES_VIDEO_CAPTIONS = `${PREFIX}/users/classes/<COURSE_ID>/lecture-captions/<LECTURE_ID>/`;
// users/classes/198c634f-d6ff-496e-a058-b814018a2e11/lecture-captions/ba7dc39b-0751-4fe6-b402-61b34ff6e599/

export const API_GET_POST_CLASSES_REVIEW = `${PREFIX}/courses/<COURSE_ID>/reviews/`;
export const API_PUT_CLASSES_REVIEW = `${PREFIX}/courses/<COURSE_ID>/reviews/<REVIEW_ID>/`;
export const API_POST_CLASSES_REPORT_REVIEW = `${PREFIX}/courses/<COURSE_ID>/report-reviews/<REVIEW_ID>/`;
export const API_PUT_CLASSES_LIKE_REVIEW = `${PREFIX}/courses/like-reviews/<REVIEW_ID>/`;
export const API_PUT_CLASSES_DISLIKE_REVIEW = `${PREFIX}/courses/dislike-reviews/<REVIEW_ID>/`;

export const API_GET_POST_CLASSES_QNA = `${PREFIX}/courses/<COURSE_ID>/qnas/`;
export const API_GET_CLASSES_QNA_REPLIES = `${PREFIX}/courses/get-qnas-replies/<QNA_ID>/`;
export const API_GET_CLASSES_QNA_FROM_SUBSECTION = `${PREFIX}/courses/<COURSE_ID>/get-qnas-from-subsection/<SUBSECTION_ID>/`;
export const API_POST_CLASSES_QNA_REPLIES = `${PREFIX}/courses/qna-replies/<QNA_ID>/`;
export const API_PUT_DELETE_CLASSES_QNA_REPLIES = `${PREFIX}/courses/qna-replies-update/<QNA_REPLY_ID>/`;
export const API_POST_CLASSES_QNA_REPORT_REPLIES = `${PREFIX}/courses/report-qna-reply/<QNA_REPLY_ID>/`;
export const API_PUT_CLASSES_QNA_UPVOT = `${PREFIX}/courses/upvote-qnas/<QNA_ID>/`;
export const API_PUT_CLASSES_QNA_REPLIES_UPVOT = `${PREFIX}/courses/upvote-qna-replies/<QNA_REPLY_ID>/`;

export const API_GET_COURSES_CERTIFICATE = `${PREFIX}/users/classes/<COURSE_ID>/certificate-check/`;
export const API_GET_COURSES_RESOURCE = `${PREFIX}/common/lecture-resource-library/<RESOURCE_ID>/`;

// Purchase history
export const API_GET_PURCHASE_HISTORY = `${PREFIX}/shopping-carts/purchase-history/`;
export const API_GET_PURCHASE_INVOICE = `${PREFIX}/shopping-carts/purchase-history/<ID>/get_invoice/`;
export const API_GET_PURCHASE_RECEIPT = `${PREFIX}/shopping-carts/purchase-history/<ID>/get-receipt/`;
export const API_GET_REFUND_VALIDATE = `${PREFIX}/shopping-carts/refund-request/validate-refund/`;
export const API_GET_PURCHASE_DETAILS = `${PREFIX}/shopping-carts/refund-request/get-purchase-details/`;
export const API_POST_SUBMIT_REFUND_REQUEST = `${PREFIX}/shopping-carts/refund-request/`;
export const API_GET_REFUND_REQUEST = `${PREFIX}/shopping-carts/refund-request/`;

// Instructor public details
export const API_GET_INSTRUCTOR_PUBLIC_DETAILS = `${PREFIX}/courses/instructor-details/<INSTRUCTOR_ID>/`;
export const API_GET_INSTRUCTOR_PUBLISHED_COURSES = `${PREFIX}/courses/instructor-details/<INSTRUCTOR_ID>/published-courses/`;
export const API_GET_INSTRUCTOR_COURSE_REVIEWS = `${PREFIX}/courses/instructor-details/<INSTRUCTOR_ID>/course-reviews/`;

// Instructor course settings
export const API_GET_INSTRUCTOR_COURSE_SETTINGS = `${PREFIX}/courses/instructor-course-setting/<COURSE_ID>/`;
export const API_POST_INSTRUCTOR_COURSE_SETTINGS_LISTING = `${PREFIX}/courses/instructor-course-setting/<COURSE_ID>/course-listing/`;
export const API_DELETE_INSTRUCTOR_COURSE_SETTINGS_DELETE = `${PREFIX}/courses/instructor-course-setting/<COURSE_ID>/delete-course/`;

// Home
export const API_GET_MY_LEARNING = `${PREFIX}/users/profile/keep-learning/`;

// Transcoded API
export const API_GET_LB_LECTURE_TRANSCODED_INFO = `${PREFIX}/users/classes/<PRODUCT_ID>/lecture-info/<LECTURE_ID>/`;
export const API_GET_LB_PREVIEW_LECTURE_TRANSCODED_INFO = `${PREFIX}/courses/preview-learning-lab/<LECTURE_ID>/`;
export const API_GET_IN_LIBRARY_TRANSCODED_INFO = `${PREFIX}/common/video-library/<LIBRARY_ID>/`;
export const API_GET_IN_LECTURE_TRANSCODED_INFO = `${PREFIX}/courses/lectures/<LECTURE_ID>/`;
export const API_GET_IN_LECTURE_ROLLBACK_INFO = `${PREFIX}/courses/lectures/<LECTURE_ID>/rollback-video/`;
export const API_GET_IN_COURSE_TRANSCODED_INFO = `${PREFIX}/courses/instructor-course/<COURSE_ID>/promo-video-info/`;
// courses/lectures/01b4e893-690b-4f0b-a211-39e8a98d87f2/rollback-video/
// Instructor Payout Wizard APIs
export const API_GET_INSTRUCTOR_PAYOUT_WIZARD_DETAILS = `${PREFIX}/users/instructor-payouts/payout-wizard/details/`;
export const API_PUT_INSTRUCTOR_PAYOUT_WIZARD_DETAILS = `${PREFIX}/users/instructor-payouts/payout-wizard/update/`;
export const API_POST_INSTRUCTOR_PAYOUT_ADD_BANK_ACCOUNT = `${PREFIX}/users/instructor-payouts/add-bank-account/`;
export const API_POST_INSTRUCTOR_PAYOUT_UPDATE_BANK_ACCOUNT = `${PREFIX}/users/instructor-payouts/<ACCOUNT_ID>/update-bank-details/`;
export const API_DELETE_INSTRUCTOR_PAYOUT_DELETE_BANK_ACCOUNT = `${PREFIX}/users/instructor-payouts/<ACCOUNT_ID>/delete-bank-account/`;
export const API_GET_INSTRUCTOR_PAYOUT_METHOD_DETAILS = `${PREFIX}/users/instructor-payouts/<ACCOUNT_ID>/payment-method/`;
export const API_POST_INSTRUCTOR_PROFILE_VERIFICATION = `${PREFIX}/users/instructor-payouts/submit-verification-application/`;

// Student course mail notification settings
export const API_GET_STUDENT_COURSES_EMAIL_PREFERENCE = `${PREFIX}/notifications/students/course-email-preferances/<COURSE_ID>/`;
export const API_POST_STUDENT_COURSES_EMAIL_PREFERENCE = `${PREFIX}/notifications/students/course-email-preferances/`;

// Instructor course mail notification settings
export const API_GET_INSTRUCTOR_COURSES_EMAIL_PREFERENCE = `${PREFIX}/notifications/instructors/course-email-preferances/<COURSE_ID>/`;
export const API_POST_INSTRUCTOR_COURSES_EMAIL_PREFERENCE = `${PREFIX}/notifications/instructors/course-email-preferances/`;
