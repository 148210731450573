import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  CartState,
  ModalComponent,
  ROUTE_HOME,
  UntilDestroy,
  selectAuthInfo,
  selectCart,
  selectIsAuthenticated,
  selectIsInstructor,
  selectUser,
  untilDestroyed,
} from '@shared';
import * as _ from 'lodash';
import { Observable, distinctUntilChanged, filter } from 'rxjs';
import {
  CategoryV2,
  LoaderService,
  RouterStoreService,
  ToastService,
  User,
  selectCategories,
  skipUndefined,
} from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('mobileNav') mobileNav!: ModalComponent;
  @ViewChild('searchRef') searchRef!: NgModel;
  selectedCtg: string = '';
  ctgLabel: string = $localize`All categories`;

  homeUrl: string = ROUTE_HOME;
  openNavigation: boolean = false;
  openAccount: boolean = false;

  isAuth$ = this.store.select(selectIsAuthenticated).pipe(filter((bool) => typeof bool === 'boolean'));
  isInstructor$ = this.store.select(selectIsInstructor).pipe(skipUndefined());
  categories$ = this.store.select(selectCategories);
  userAuthInfo$ = this.store.select(selectAuthInfo);

  showSearchBar: boolean = false;
  searchKeyword: string = '';

  cart$!: Observable<CartState>;
  cartCount: number = 0;

  user?: User;
  isPreviewCourse: boolean = false;
  params: Params = {};
  queryParams: Params = {};

  isLoading$ = this.loaderService.response('loading-me');

  constructor(
    private router: Router,
    private store: Store,
    private readonly toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private routerStore: RouterStoreService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.queryParams = params;
      if (params['preview'] === 'true') {
        this.isPreviewCourse = true;
      } else {
        this.isPreviewCourse = false;
      }
    });

    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
      });

    this.cart$ = this.store.select(selectCart);
    this.cart$.pipe(untilDestroyed(this)).subscribe((cart) => {
      if (cart?.courses) {
        this.cartCount = cart.courses.length;
      }
    });

    this.store
      .select(selectUser)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe((user) => (this.user = user));
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  handleClickOutside() {
    this.openNavigation = false;
  }

  mostPopular(categories: CategoryV2[]): CategoryV2[] {
    return _.slice(categories, 2, 5); // TODO: Get/Fetch the real most popular
  }

  filterCategory(categories: any) {
    if (!this.selectedCtg || this.selectedCtg == 'all') {
      // this.ctgLabel = 'All categories';
      return categories;
    }
    const parentCtg = _.find(categories, { id: this.selectedCtg });
    if (parentCtg) {
      // this.ctgLabel = parentCtg.title;
      return parentCtg.subcategories;
    }

    return categories;
  }

  onSearch(event: any) {
    const trimmedSearchTerm = this.searchKeyword.trim();
    if (trimmedSearchTerm) {
      this.router.navigate(['/search'], { queryParams: { search: trimmedSearchTerm } });
      this.searchKeyword = '';
    } else {
      this.toastService.message({
        message: $localize`The search field will not be empty!`,
      });
    }
  }
}
