import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AlternativePriceModel,
  API_CHECKOUT_ORDER,
  API_CHECKOUT_PAYMENT_STRATEGIES_LIST,
  API_CHECKOUT_PAYPAL_CALLBACK,
  API_CHECKOUT_SAVE_CARD_LIST,
  API_CONSTANTS_COUNTRIES,
  API_GET_SUPPORTED_CURRENCY,
  API_PAYMENT_VERIFY_TOKEN,
  ConstantCountryOption,
  encodeURL,
  HttpService,
  PaymentMethodsList,
} from 'thkee-common';
import { SaveCardModel } from './checkout-state.model';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(private readonly http: HttpService) {}

  // Checkout related
  getPaymentMethods(params = {} as object): Observable<PaymentMethodsList[]> {
    return this.http.get<PaymentMethodsList[]>(encodeURL(API_CHECKOUT_PAYMENT_STRATEGIES_LIST, params));
  }

  verifyPayment(token: string): Observable<any> {
    return this.http.get<any>(API_PAYMENT_VERIFY_TOKEN.replace('<TOKEN>', token));
  }

  getOrder(orderId: string): Observable<any> {
    return this.http.get<any>(API_CHECKOUT_ORDER.replace('<ID>', orderId));
  }

  paypalCallback(token: string): Observable<any> {
    return this.http.get<any>(API_CHECKOUT_PAYPAL_CALLBACK.replace('<TOKEN>', token));
  }

  getSavedCards(params = {} as object): Observable<SaveCardModel[]> {
    return this.http.get<SaveCardModel[]>(encodeURL(API_CHECKOUT_SAVE_CARD_LIST, params));
  }

  getConstantCountries(params = {} as object): Observable<ConstantCountryOption[]> {
    return this.http.get<ConstantCountryOption[]>(encodeURL(API_CONSTANTS_COUNTRIES, params));
  }

  getAlternativePrice(params = {} as object): Observable<AlternativePriceModel> {
    return this.http.get<AlternativePriceModel>(encodeURL(API_GET_SUPPORTED_CURRENCY, params));
  }
}
