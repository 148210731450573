import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent } from '@shared/components/modal';
import { QaService } from '@shared/services';
import { DropDownAction, QnA } from '@shared/store';
import { ModalService, ToastService } from 'thkee-common';

@Component({
  selector: 'app-qa-q-card',
  templateUrl: './qa-q-card.component.html',
  styleUrls: ['./qa-q-card.component.scss'],
})
export class QaQCardComponent implements OnInit {
  @Input() data!: QnA;
  @Input() params: any;
  @Input() isListView: boolean = true;
  @Output() selectDropDownEvent = new EventEmitter<Object>();
  @ViewChild('reportAbuseModal') private reportAbuseModal!: ModalComponent;
  @Input() QnaID: string = '';
  @Input() isTitleClickable: boolean = false;

  courseProjectId: string = '';

  // Report Abuse Form
  reportAbuseForm = new FormGroup({});
  reportAbuseFormModel = { issue: '', description: '' };
  reportAbuseFormField: FormlyFieldConfig[] = [
    {
      key: 'issue',
      type: 'select',
      props: {
        label: '',
        placeholder: $localize`Select your issues`,
        required: true,
        wrapClass: 'block mb-0',
        options: [
          { label: $localize`Inappropriate course content`, value: 'Inappropriate course content' },
          { label: $localize`Inappropriate behaviour`, value: 'Inappropriate behaviour' },
          { label: $localize`Thkee policy Violation`, value: 'Thkee policy Violation' },
          { label: $localize`Spammy content`, value: 'Spammy content' },
        ],
        stylish: true,
        hideRequiredMarker: true,
      },
    },
    {
      key: 'description',
      type: 'textarea',
      props: {
        label: '',
        placeholder: $localize`Write issue details.....`,
        minHeight: '100px',
        maxHeight: '150px',
        feedback: 0,
        required: true,
      },
      validation: {
        messages: {
          required: $localize`The issue details is required!`,
        },
      },
    },
  ];

  constructor(
    private qaservice: QaService,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.courseProjectId = this.params ? this.params : this.QnaID;
  }

  public dropdownAction!: DropDownAction;
  onClickDropdown(action: string, id: string) {
    this.dropdownAction = { action: action, id: id };
    if (action === 'not-relevant') {
      this.qaservice.markNotRelevant(this.dropdownAction.id).subscribe((res) => {
        this.selectDropDownEvent.emit(this.dropdownAction);
        this.toastService.message({
          message: $localize`Marked as not relevant success`,
        });
      });
    } else {
      this.reportAbuseModal.open();
    }
  }

  // Report abuse
  onSubmitReportAbuse() {
    this.reportAbuseModal.close();
    this.qaservice.reportQnAAll(this.dropdownAction.id, this.reportAbuseForm.value).subscribe({
      next: (res) => {
        this.selectDropDownEvent.emit(this.dropdownAction);
        this.toastService.message({
          message: $localize`Successfully report this QnA`,
        });
      },
      error: (err) => {
        this.toastService.error({
          message: err.error.error,
        });
      },
    });
  }
}
