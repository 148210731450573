
<section class="relative top-header bg-[#020E26] bg-no-repeat bg-cover bg-left-top" [style.background-image]="'url(../../../../../assets/images/hero/hero-one/texture.png)'">
   <div class="container">
      <div class="content_part py-10 lg:py-[72px]">
         <div class="grid grid-cols-12 gap-4">
            <div class="w-full lg:max-w-2xl col-span-12 flex flex-col gap-2 justify-end text-center md:col-span-7 ltr:md:text-left  rtl:md:text-right">
               <h1 class="text-3xl lg:text-5xl md:text-3xl font-bold text-white leading-normal" i18n>Performance Speaks, No Words Needed.</h1>
               <p class="text-base lg:text-lg md:text-base font-normal text-white text-opacity-75" i18n>Words can only say so much. Our platform's quality and performance speak for themselves.</p>
            </div>

            <div class="col-span-12 md:col-span-5 flex flex-col gap-3">
               <div class="m-auto w-full max-w-[320px] h-[380px] relative">
                  <ng-container *ngIf="isLoadingPopularCourse; else loadedPopularCourse">
                     <div>
                     <swiper [config]="categorySlider" class="!px-4 !py-5">
                        <ng-template swiperSlide class="!h-full"
                           *ngFor="let course of [].constructor(1)">
                           <app-course-card [isLoading]="true"></app-course-card>
                           </ng-template>
                     </swiper>
                     </div>
                  </ng-container>

                  <ng-template #loadedPopularCourse class="!h-full absolute">
                     <swiper [config]="categorySlider" class="!h-full w-full absolute px-4 sm:px-0 bg-white shadow-lg-c rounded-3xl overflow-hidden">
                        <ng-template swiperSlide class="!h-full flex justify-center"  *ngFor="let course of popularCourses">
                           <div class="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-25"></div>
                           <div class="absolute w-full p-3 bottom-0 left-0">
                              <div class="flex flex-row items-center justify-start gap-2">
                                 <svg-icon src="@assets/images/icons/star-solid.svg" class="btn-icon text-primary-500"></svg-icon>
                                 <span class="text-white text-sm font-medium">{{ course.total_reviews }}</span>
                                 <span class="text-white text-sm">{{ course.user?.fullname }}</span>
                              </div>
                              <h3 class=" text-white font-medium text-2xl">{{course.title}}</h3>
                           </div>
                           <img [src]="course.image_url" class="w-auto h-full max-w-none" alt="Thkee course image">
                        </ng-template>
                     </swiper>
                  </ng-template>
               </div>
               <div class="swiper-pagination relative bottom-0"></div>
            </div>
         </div>
      </div>
   </div>
   <div class="absolute w-1/2 h-full bg-no-repeat bg-cover bg-right bottom-0 right-0" [style.background-image]="'url(../../../../../assets/images/hero/hero-one/bg-gradient.png)'"></div>
</section>