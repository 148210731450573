import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { I18nService } from '@app/i18n';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  CartActions,
  ModalConfirmComponent,
  ModalLoginComponent,
  selectIsAuthenticated,
  selectUser,
  ToastComponent,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import { AuthenticationService } from '@shared/services/auth/authentication.service';
import { GeolocationService } from '@shared/services/public/geolocation.service';
import { NotificationsComponent } from 'projects/thkee-common/src/lib/components/notifications/notifications.component';
import {
  CategoryActions,
  CredentialsService,
  isDefined,
  LoaderService,
  LocalStorage,
  Logger,
  ModalService,
  NotificationsService,
  skipUndefined,
  ToastService,
  TopicActions,
  User,
} from 'thkee-common';

const log = new Logger('App');
const rtlLangCodes = ['ar-SA'];

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalConfirm')
  private modalConfirm!: ModalConfirmComponent;
  @ViewChild('notificationContainer')
  private notificationsContainer?: NotificationsComponent;

  @ViewChild('modalLogin')
  private modalLogin!: ModalLoginComponent;

  @ViewChild('toastComponent')
  private toastComponent!: ToastComponent;

  isLogged$ = this.store.select(selectIsAuthenticated).pipe(skipUndefined());
  notificationRect?: DOMRect;
  reverifyingAccount = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private titleService: Title,
    private i18nService: I18nService,
    private store: Store,
    private modalService: ModalService,
    private authService: AuthenticationService,
    private credentialService: CredentialsService,
    private toastService: ToastService,
    private geolocationService: GeolocationService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationsService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.geolocationService.init();
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init();

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    onNavigationEnd
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(title);
        }
      });

    // Init modal
    this.modalService.modal$
      .pipe(
        filter(isDefined),
        tap((data) => log.debug(data)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        log.debug('open modal', this.modalConfirm);
        this.modalConfirm.open();
      });

    // Toster
    this.toastService.toast$
      .pipe(
        filter(isDefined),
        tap((data) => log.debug(data)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.toastComponent.open();
      });

    // Init ngrx store
    this.initializeAuthenticationData();
    this.store.dispatch(CategoryActions.loadCategories());
    this.store.dispatch(TopicActions.loadTopics());
    // Force Login of any login required throught the page it self it will displau modal login/signup
    this.authService.loginActionState
      .pipe(
        filter(isDefined), // FIXME:: this will never happens, please consider to remove this logic
        // tap((data) => log.debug(data)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (this.modalLogin) {
          if (data.openLoginModal) {
            this.modalLogin.open();
          } else {
            this.modalLogin.close();
          }
        }
      });
    this.setGuestToken();
  }

  // Guest token set
  setGuestToken() {
    if (!this.credentialService.isLogged()) {
      this.credentialService.getGuestToken().subscribe((res) => {
        if (!LocalStorage.getObject<string>('guest_cart_token')) {
          LocalStorage.setValue('guest_cart_token', res.guest_token);
        }
      });
    }
    this.initializeNotifications();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  ngAfterViewInit() {
    this.activatedRoute.fragment.subscribe((fragment) => {
      if (fragment && fragment.includes('feedback')) {
        // scroll on element for feedback
        setTimeout(() => {
          this.scrollToElement(fragment);
        }, 500);
      }
    });
  }

  handleNotificationViewRect(event: DOMRect) {
    Promise.resolve().then(() => {
      this.notificationRect = event;
      this.cdr.detectChanges();
    });
  }

  private initializeNotifications() {
    this.store
      .select(selectUser)
      .pipe(
        distinctUntilChanged((pre, cur) => pre?.id === cur?.id),
        untilDestroyed(this)
      )
      .subscribe((user) => {
        if (!user) {
          this.notificationService.clear();
          return;
        }

        this.processVerifyingAccountMessage(user);
      });
  }

  private processVerifyingAccountMessage(user: User) {
    if (!user.is_active) {
      this.notificationService.add({
        id: 'new-user-required-verify-email',
        type: 'warning',
        message: $localize`Please verify your account by clicking the link in your email. Didn't get it? <a class="resend-email-btn cursor-pointer underline">Click here to resend</a>.`,
      });

      setTimeout(() => {
        this.notificationsContainer?.getChild('.resend-email-btn')?.addEventListener('click', () => {
          this.authService.reverifyEmail().subscribe(() => {
            this.toastService.message({
              message: $localize`Verification email resent. Please check your inbox.`,
              type: 'message',
            });
          });
        });
      });
    }
  }

  private scrollToElement(elementId: string): void {
    const element = this.el.nativeElement.querySelector(`#${elementId}`);
    if (element) {
      element.classList.add('animate-bounce');
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - 100, behavior: 'smooth' });
      setTimeout(() => {
        element.classList.remove('animate-bounce');
      }, 5000);
    }
  }

  get modal$() {
    return this.modalService.modal$;
  }

  get forceLogin$() {
    return this.authService.loginActionState;
  }

  get toast$() {
    return this.toastService.toast$;
  }

  private initializeAuthenticationData() {
    if (this.credentialService.hasCredentials()) {
      this.store.dispatch(AuthActions.userLoad());
      this.store.dispatch(AuthActions.userAuthInfoLoad());
      return;
    } else {
      setTimeout(() => {
        this.loaderService.loaderAction('loading-me', 'success');
      }, 500);
    }

    this.store.dispatch(CartActions.loadCart());
    this.store.dispatch(AuthActions.useAsGuest());
  }
}
