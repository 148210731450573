import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent, MyClassService } from '@shared';
import { AuthenticationService } from '@shared/services/auth/authentication.service';
import { CoursesDetailsService } from '@shared/services/public/courses.service';
import {
  CartActions,
  CartState,
  CourseContentActions,
  selectCart,
  selectCourseContent,
  selectIsAuthenticated,
  ZeroPaymentModal,
} from '@shared/store';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { Course, CourseV2, LocalStorage, ModalService, skipUndefined, ToastService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseCardComponent implements OnInit, OnDestroy {
  @Input() courseData: Partial<Course> = {};
  @Input() course?: CourseV2;
  @Input() isFeatured: boolean = false;
  @Input() disabledHover: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() mobileView: boolean = true;
  @Input() myClassView: boolean = false;

  @Input() isCardLoader: boolean = false;
  @Input() loadingPrices: { [key: string]: BehaviorSubject<boolean> } = {};
  @Output() selectedCardItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropdownAction: EventEmitter<{ data: Partial<Course>; action: string }> = new EventEmitter<{
    data: Partial<Course>;
    action: string;
  }>();
  @Output() courseCardAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  private courseContentSubscription: Subscription = new Subscription();
  private cartItemsSubscription: Subscription = new Subscription();
  private freeCourseSubscription: Subscription = new Subscription();

  courseId: any = '';
  cart$!: Observable<CartState>;
  inCart: boolean = false;

  private favorite = new BehaviorSubject<boolean>(false);
  public favorite$ = this.favorite.asObservable();
  isLogged$ = this.store.select(selectIsAuthenticated).pipe(skipUndefined());

  @ViewChild('newReview') newReview!: ModalComponent;

  constructor(
    private store: Store,
    private router: Router,
    private myClassService: MyClassService,
    private coursesDetailsService: CoursesDetailsService,
    private toastService: ToastService,
    private readonly modalService: ModalService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.courseId = this.courseData ? this.courseData.id : this.course?.id;
    this.cart$ = this.store.select(selectCart);
    this.cart$.pipe(take(1), untilDestroyed(this)).subscribe((cart) => {
      if (_.includes(cart.courses, this.courseId)) {
        this.inCart = true;
      }
    });
  }

  addToCart() {
    let cartItems: (string | number)[] = [];
    this.cartItemsSubscription.add(
      this.store.select(selectCart).subscribe((items) => {
        cartItems = items.courses;
      })
    );

    const inCart = Boolean(cartItems.find((item) => (item === this.courseData.id ? true : false)));
    if (this.courseId && !inCart) {
      const courseData = this.courseData ? this.courseData : this.course;
      this.store.dispatch(CartActions.addToCart(courseData));
      this.inCart = true;
      this.courseCardAction.emit(this.inCart);
    } else {
      this.toastService.error({
        message: $localize`Already added in cart`,
      });
    }
  }

  isLoadingLearningLab: boolean = false;
  goToLearningLab() {
    if (this.courseId) {
      this.isLoadingLearningLab = true;
      this.selectedCardItem.emit(this.courseData);
      this.store.dispatch(CourseContentActions.resetCourseOverview({ overview: {} }));
      this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.courseId }));
      this.courseContentSubscription.add(
        this.store.select(selectCourseContent).subscribe({
          next: (courses) => {
            if (courses.courseOrerview.code) {
              const code = courses.courseOrerview.code;
              const section = courses.courseOrerview.last_recorded.section_chain_id;
              const subsection = courses.courseOrerview.last_recorded.subsection_chain_id;
              if (code) {
                this.isLoadingLearningLab = false;
                const route = `/learning-lab/${this.courseData.id}/overview/${section}/${subsection}/${code}`;
                this.router.navigate([route]);
              }
            }
          },
          error: (err) => {
            this.isLoading = false;
          },
        })
      );
    }
  }

  toggleArchive() {
    if (this.courseId) {
      this.myClassService.getClassesToggleArchive(this.courseId).subscribe((res: Course) => {
        this.courseData.archived = res.archived;
        this.dropdownAction.emit({ data: this.courseData, action: 'archived' });
      });
    }
  }

  toggleFavorite() {
    this.isLogged$.pipe(take(1)).subscribe((isLogged) => {
      if (isLogged) {
        if (this.courseId) {
          this.coursesDetailsService.coursesFavoriteToggle({ product: this.courseId }).subscribe({
            next: (res: Course) => {
              this.courseData.favorite = res.status === 'Added' ? true : false;
              this.favorite.next(this.courseData.favorite);
              this.dropdownAction.emit({ data: this.courseData, action: 'favorite' });
              this.courseCardAction.emit(this.courseData.favorite);
            },
          });
        }
      } else {
        this.authService.loginActionState.next({ openLoginModal: true, redirect: this.router.url });
      }
    });
  }

  demoImgUrl = 'https://placehold.co/70x50';

  get isOwned() {
    return this.course ? this.course.owned : this.courseData?.owned;
  }

  get title() {
    return this.course ? this.course.title : this.courseData?.title;
  }

  /**
   * Sorry, i dont have time to findout the different between course vs course data,
   * but base on the current logic in other places in this component,
   * the course information seems from the course and courseData in order
   */
  get finalCourse() {
    return this.course || this.courseData;
  }

  get author() {
    if (this.courseData) {
      return this.courseData.user?.fullname;
    }
    return '';
  }

  get totalStar(): number[] {
    return Array.from({ length: this.courseData.average_rating ?? 0 }, (_, index) => index + 1);
  }

  get isBestSeller(): boolean {
    return this.courseData.is_bestseller ?? false;
  }

  get totalRate(): number {
    return this.courseData.total_rate ?? 0;
  }

  get totalReviews(): number {
    return this.courseData.total_reviews ?? 0;
  }

  get progress(): number {
    return this.courseData.progress ?? 0;
  }

  get hasProgress(): boolean {
    return this.courseData && typeof this.courseData.progress !== undefined && !!this.courseData.progress;
  }

  get duration(): number {
    return this.courseData.duration ?? 0;
  }

  get salePrice(): number {
    return this.courseData.sale_price ?? 0;
  }

  get price(): number {
    return this.courseData.price ?? 0;
  }

  get isFavorite() {
    if (this.courseData) {
      return this.courseData.favorite;
    }
    return _.sample([true, false]);
  }

  get lectureTotal(): number {
    return this.courseData.lecture_total ?? 0;
  }

  get featuredImage() {
    return this.course ? this.course?.image_url : this.courseData?.image_url;
  }

  get description() {
    return this.course ? this.course.desc : this.courseData.desc;
  }

  get date() {
    return this.course ? this.course.date_published : this.courseData.date_updated;
  }

  get objectives() {
    if (this.courseData) {
      return this.courseData.objectives;
    }
    return [];
  }

  get permalink() {
    if (this.courseData) {
      return ['/course', this.courseData.slug];
    }
    if (this.course) {
      return ['/course', this.course.slug];
    }
    return ['/home'];
  }

  get isFree() {
    return this.courseData?.is_free;
  }

  isLoadingFreeCourse: boolean = false;
  buyFreeCourse() {
    let body = {
      payables: this.courseData.code,
      // payables: 0,
      referrals: LocalStorage.getObject('referrals') ? LocalStorage.getObject('referrals') : {},
      campaign: '',
      campaign_encounter_date: '',
    };
    this.isLoadingFreeCourse = true;
    this.freeCourseSubscription.add(
      this.coursesDetailsService.buyFreeCourse(body).subscribe({
        next: (res: ZeroPaymentModal) => {
          this.isLoadingFreeCourse = false;
          this.router.navigate(['/student/dashboard']);
        },
        error: ({ error }) => {
          error.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      })
    );
  }

  // Review options

  newReviewState: 'new' | 'thankyou' = 'new';
  selectedRate: number = 0;
  private updateReviewSubscription = new Subscription();
  formReview: FormGroup = new FormGroup({});
  modelReview = {};
  fieldsReview: FormlyFieldConfig[] = [
    {
      key: 'content',
      type: 'textarea',
      props: {
        minLength: 6,
        maxLength: 1000,
        placeholder: $localize`Share your experience with this course!`,
        minHeight: '150px',
        maxHeight: '300px',
        hideLabel: true,
        required: true,
      },
      validation: {
        messages: {
          minLength: $localize`Review can't be lower than 6 characters.`,
        },
      },
    },
  ];

  addReviewPopup() {
    this.newReview.open();
    this.resetReview();
    console.log('courseData', this.courseData);
  }
  cancleReviewPopup() {
    this.newReview.close();
    this.resetReview();
  }

  // POST Revviews
  submitReview() {
    if (this.formReview.valid) {
      this.newReview.close();
      this.modelReview = { ...this.modelReview, rate: this.selectedRate };

      this.updateReviewSubscription.add(
        this.myClassService.postClassesReview(this.courseData.id as any, this.modelReview).subscribe({
          next: (data: any) => {
            this.newReviewState = 'thankyou';
            setTimeout(() => {
              this.resetReview();
            }, 1000);
          },
          error: (err) => {
            this.resetReview();
            this.toastService.error({
              message: err.error.error,
            });
          },
        })
      );
    }
  }

  resetReview() {
    this.formReview.reset();
    this.selectedRate = 0;
    this.newReviewState = 'new';
    this.formReview.markAsUntouched();
  }

  // Destroyed all subscriptions
  ngOnDestroy(): void {
    this.courseContentSubscription.unsubscribe();
    this.cartItemsSubscription.unsubscribe();
    this.freeCourseSubscription.unsubscribe();
    this.updateReviewSubscription.unsubscribe();
  }
}
