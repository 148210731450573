import { createFeature, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { CourseContentActions } from './learning-lab.actions';
import { CourseSection, CourseSubSection, initialCourseContentState } from './learning-lab.model';

export const courseContentReducer = createReducer(
  initialCourseContentState,
  on(CourseContentActions.loadCourseContentSuccess, (state, data) => {
    return {
      ...state,
      ...data,
    };
  }),

  on(CourseContentActions.loadCourseOverviewSuccess, (state, overview) => {
    return {
      ...state,
      courseOrerview: overview,
    };
  }),

  on(CourseContentActions.resetCourseOverview, (state, overview) => {
    return {
      ...state,
      courseOrerview: overview.overview,
    };
  }),

  on(CourseContentActions.nextActivity, (state) => {
    const activeSection = state.results?.find((s) => s.chain_id === state.sectionId);
    const lastSubsection = _.last(activeSection?.subsections);
    // const section = state.results.find((data) => data.chain_id === state.sectionId);
    // console.log(section);
    return {
      ...state,
      completedActivity: {
        ...state.completedActivity,
        [state.sectionId]: state.subsectionId === lastSubsection?.chain_id,
        [state.subsectionId]: true,
      },
    };
  }),

  on(CourseContentActions.prevActivity, (state) => {
    return state;
  }),

  on(CourseContentActions.selectActivity, (state, { sectionId, subsectionId }) => {
    return {
      ...state,
      sectionId: sectionId,
      subsectionId: subsectionId,
    };
  }),

  on(CourseContentActions.markedAsCompleteSuccess, (state) => {
    return {
      ...state,
    };
  }),

  on(CourseContentActions.loadCourseCertificateSuccess, (state, certificate) => {
    return {
      ...state,
      certificate: certificate,
    };
  }),

  on(CourseContentActions.markedAsReadSuccess, (state, { section_chain_id, subsection_chain_id, is_completed }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const sectionIndex = newState.results.findIndex((section: CourseSection) => section.chain_id === section_chain_id);
    if (sectionIndex !== -1) {
      const subsectionIndex = newState.results[sectionIndex].subsections.findIndex(
        (subsection: CourseSubSection) => subsection.chain_id === subsection_chain_id
      );
      if (subsectionIndex !== -1) {
        newState.results[sectionIndex].subsections[subsectionIndex].is_completed = is_completed;
      }
    }
    return {
      ...state,
      results: [...newState.results],
    };
  }),

  on(CourseContentActions.submitAssignment, (state, { courseId, assignId, payload }) => {
    const sectionId = state.sectionId;
    const subsectionId = state.subsectionId;

    const rearrangedSubmissions = payload.content.map((item) => ({
      question_id: item.question_id,
      answer: item.answer,
      feedback: '',
      student_assignment: assignId,
    }));

    const newState = JSON.parse(JSON.stringify(state));

    // Nested subsection
    const sectionIndex = newState.results.findIndex((section: CourseSection) => section.chain_id === sectionId);
    if (sectionIndex !== -1) {
      const subsectionIndex = newState.results[sectionIndex].subsections.findIndex(
        (subsection: CourseSubSection) => subsection.chain_id === subsectionId
      );
      if (subsectionIndex !== -1) {
        newState.results[sectionIndex].subsections[subsectionIndex].submission.push(...rearrangedSubmissions);
      }
    }

    // Plain subsection
    const subsectionIndex2 = newState.subsections.findIndex(
      (subsection: CourseSubSection) => subsection.chain_id === subsectionId
    );

    if (subsectionIndex2 !== -1) {
      newState.subsections[subsectionIndex2].submission.push(...rearrangedSubmissions);
    }

    const updatedState = {
      ...state,
      results: [...newState.results],
      subsections: [...newState.subsections],
    };

    return updatedState;
  }),

  on(CourseContentActions.submitQuiz, (state, { courseId, quizId, payload }) => {
    const sectionId = state.sectionId;
    const subsectionId = state.subsectionId;

    const rearrangedSubmissions = payload.content.map((item) => ({
      id: item.id,
      question: item.question_id,
      answer: item.answer_id,
      student_quiz: quizId,
    }));

    const newState = JSON.parse(JSON.stringify(state));

    // Nested subsection
    const sectionIndex = newState.results.findIndex((section: CourseSection) => section.chain_id === sectionId);
    if (sectionIndex !== -1) {
      const subsectionIndex = newState.results[sectionIndex].subsections.findIndex(
        (subsection: CourseSubSection) => subsection.chain_id === subsectionId
      );
      if (subsectionIndex !== -1) {
        newState.results[sectionIndex].subsections[subsectionIndex].submission.push(...rearrangedSubmissions);
      }
    }

    // Plain subsection
    const subsectionIndex2 = newState.subsections.findIndex(
      (subsection: CourseSubSection) => subsection.chain_id === subsectionId
    );

    if (subsectionIndex2 !== -1) {
      newState.subsections[subsectionIndex2].submission.push(...rearrangedSubmissions);
    }

    const updatedState = {
      ...state,
      results: [...newState.results],
      subsections: [...newState.subsections],
    };

    return updatedState;
  })
);

export const courseContentFeature = createFeature({
  name: 'courseContent',
  reducer: courseContentReducer,
});
