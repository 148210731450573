import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import { Logger } from '../services';
import { DomRectService } from '../services/dom-rect.service';
import { ScrollTracker } from './scroll-tracker';
import { ScrollTrackerDirective } from './scroll-tracker.directive';
const logger = new Logger();

@Directive({
  selector: '[thkScrollLoadMore]',
  providers: [
    DomRectService
  ]
})
export class ScrollLoadMoreDirective implements OnInit, OnDestroy {
  @Output() loadMore = new EventEmitter<void>();
  @Input() window = true;

  @HostListener('window:scroll')
  private onScroll() {
    this.windowScrollTracker.onScroll();
  }

  @HostListener('window:scrollend', [])
  private onScrollend(): void {
    this.windowScrollTracker.onScrollend();
  }

  private subscription = new Subscription();
  private tempCheckedInViewport = false;
  private windowScrollTracker = new ScrollTracker();

  constructor(
    private domRectTracker: DomRectService,
    private elRef: ElementRef<HTMLElement>,
    @Optional() private scrollTrackerDirective?: ScrollTrackerDirective,
  ) {}

  ngOnInit(): void {
    this.trackLoadMoreContainerRect();
    this.trackScrollBehaviour();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private trackLoadMoreContainerRect() {
    this.subscription = this.domRectTracker.rectChanges.pipe(
      debounceTime(1000) // wait for children element to be stable
    ).subscribe(() => {
      if (this.isInViewport()) {
        this.loadMore.next();
      }
    });
  }

  private trackScrollBehaviour() {
    const tracker = this.getScrollTracker();

    if (!tracker) {
      return;
    }

    this.subscription.add(
      tracker.scrollingDown.subscribe((value) => {
        if (value === false) {
          if (this.tempCheckedInViewport) {
            this.loadMore.next();
          }
          this.tempCheckedInViewport = false;
          return;
        }

        if (this.tempCheckedInViewport) {
          return;
        }

        this.tempCheckedInViewport = this.isInViewport();
      })
    );
  }

  private isInViewport() {
    const rect = this.elRef.nativeElement.getBoundingClientRect();
    return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
  }

  private getScrollTracker() {
    if (this.window) {
      return this.windowScrollTracker;
    }

    if (!this.scrollTrackerDirective) {
      logger.warn('❌ Please add the thkScrollTracker directive to the scrollable element that contains the current component for the load more function to work as expected.');
      return undefined;
    }

    return this.scrollTrackerDirective.tracker;
  }
}
