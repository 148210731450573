import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  CourseContentActions,
  CourseSection,
  CoursesContent,
  ResourcesLists,
  selectActiveSectionID,
  selectActiveSubSectionID,
  selectCompletedActivity,
  selectCourseContent,
} from '@shared';
import { MyClassService } from '@shared/services';
import { Observable, combineLatest, distinctUntilChanged, filter, tap } from 'rxjs';
import { LoaderService, Logger, RouterStoreService, ToastService } from 'thkee-common';
const log = new Logger('LearninglabSidebarComponent');

@UntilDestroy()
@Component({
  selector: 'app-learninglab-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class LearninglabSidebarComponent implements OnInit {
  searchText: string = '';

  sectionExpandedMap: Record<string, boolean> = {};
  params: any = {};
  sectionsData: CourseSection[] = [];
  courseDetail$!: Observable<CoursesContent>;
  activeSectionId$!: Observable<string>;
  activeSubsectionId$!: Observable<string>;
  completedActivity$!: Observable<{ [sectionOrSubsectionId: string]: boolean }>;
  resources_list: { resource_name: string | undefined; resource_id: string }[] = [];
  isLoading$ = this.loaderService.response('learning-lab-sidebar');

  constructor(
    private routerStore: RouterStoreService,
    private store: Store,
    private myClassService: MyClassService,
    private router: Router,
    private loaderService: LoaderService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.courseDetail$ = this.store
      .select(selectCourseContent)
      .pipe(filter((courseDetail) => (courseDetail.results?.length ?? 0) > 0));

    this.activeSectionId$ = this.store.select(selectActiveSectionID).pipe(
      distinctUntilChanged(),
      tap((activeSectionId) => {
        return (this.sectionExpandedMap[activeSectionId] = true);
      })
    );

    this.activeSubsectionId$ = this.store.select(selectActiveSubSectionID);

    this.completedActivity$ = this.store.select(selectCompletedActivity);

    this.courseDetail$.pipe(untilDestroyed(this)).subscribe((courseDetail) => {
      this.sectionsData = courseDetail.results ?? [];
      this.sectionsData.forEach((section) => {
        this.sectionExpandedMap[section.chain_id] = true;
      });
    });

    combineLatest([this.routerStore.getParams(), this.courseDetail$])
      .pipe(untilDestroyed(this))
      .subscribe(([params]) => {
        // this.store.dispatch(
        //   LearningLabActions.selectActivity({
        //     sectionId: params['section'],
        //     subsectionId: params['subsection'],
        //   })
        // );
        this.params = params;
      });
  }

  marked(data: any, event: any) {
    event.stopPropagation();
    const isCompleted: boolean = data.is_completed ? false : true;
    let body = {
      // position: 5,
      subsection: data.id,
      is_completed: isCompleted,
      // watched_duration: 25,
    };
    this.myClassService.markedAsRead(body, this.params['overviewcode']).subscribe({
      next: (res) => {
        this.store.dispatch(CourseContentActions.markedAsReadSuccess(res));
        // this.store.dispatch(CourseContentActions.loadCourseContent({ product_id: this.params['courseId'] }));
        // this.store.dispatch(CourseContentActions.loadCourseOverview({ product_id: this.params['courseId'] }));
        this.store.dispatch(CourseContentActions.loadCourseCertificate({ product_id: this.params['courseId'] }));
      },
      error: (err) => {
        this.toastService.error({
          message: $localize`Lecture marked as unread failed!`,
        });
      },
    });
  }

  selectActivity(sectionId: string, subsectionId: string, data: any) {
    this.router.navigate([
      'learning-lab',
      this.params.courseId,
      this.params.tab,
      sectionId,
      subsectionId,
      this.params.overviewcode,
    ]);
    this.store.dispatch(
      CourseContentActions.selectActivity({
        sectionId,
        subsectionId,
      })
    );
    //https://api.qa.thkee.dev/api/v2/courses/ba1eef74-8041-4ac8-a33c-e7a8d97d9421/assignment-submission/83a0fb8f-21e6-4720-97a8-8d01221ace2c/
    // [{
    //   "question_id": "c2fda571-d0dd-4e70-a598-4dfbb8f4c997",
    //   "answer": "Write a Python function sum_of_evens(n) that calculates the sum of all even numbers from 1 to n, inclusive. "
    // }]
  }

  // selectSection(sectionId: string) {
  //   this.store.dispatch(
  //     LearningLabActions.selectSection({
  //       sectionId,
  //       subsectionId: this.sectionsData?.find((s) => s.id === sectionId)?.subsections[0].id ?? '',
  //     })
  //   );
  // }

  // selectSubsection(subsectionId: string) {
  //   this.store.dispatch(LearningLabActions.selectSubsection({ subsectionId }));
  // }

  getUrl(sectionId: string, subsectionId: string = '', event: Event) {
    event.stopPropagation();
    let route = '';
    if (subsectionId) {
      route = `/learning-lab/${this.params['courseId']}/${this.params.tab}/${sectionId}/${subsectionId}/${this.params['overviewcode']}`;
    } else {
      const section = this.sectionsData?.find((s) => s.id === sectionId);
      const subsectionId = section?.subsections[0]?.id;
      if (subsectionId) {
        route = `/learning-lab/${this.params['courseId']}/${this.params.tab}/${sectionId}/${subsectionId}/${this.params['overviewcode']}`;
      }
    }
    this.router.navigate([route]);
  }

  // Resources
  showResource: boolean[] = [];
  onClickResource(resources: ResourcesLists[], id: number, event: Event) {
    this.showResource[id] = !this.showResource[id];
    event.stopPropagation();
    const res: { resource_name: string | undefined; resource_id: string }[] = [];
    resources.forEach((f: ResourcesLists) => {
      res.push({
        resource_id: f.id,
        resource_name: f.file?.split('/').slice(-1)[0],
      });
    });
    this.resources_list = res;
  }

  isCompleted(section: CoursesContent) {
    let hasuncompleted = section.subsections.filter(
      (sec) => sec.is_completed === false || sec.is_completed === null
    ).length;
    return hasuncompleted > 0 ? false : true;
  }

  // Duration convert :hr,min,sec
  formatDuration(duration: number): string {
    const totalSeconds = Math.round(duration);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    if (totalSeconds < 60) {
      return `${seconds}sec`;
    } else if (hours > 0) {
      return `${hours}hr ${minutes}min`;
    } else {
      return `${minutes}min`;
    }
  }

  hideDropdown(id: number) {
    this.showResource[id] = false;
  }

  openPdf(resourceId: string) {
    this.myClassService.getCoursesResource(resourceId).subscribe({
      next: (res) => {
        window.open(res.file_url, '_blank');
      },
      error: (err) => {
        // window.open(this.link, '_blank');
        this.toastService.error({
          message: $localize`Lecture resource download failed!`,
        });
      },
    });
  }

  public link: string = 'https://www.gstatic.com/webp/gallery/2.jpg';
}
