import { createAction, props } from '@ngrx/store';
import {
  AssignmentSubmissionPayload,
  Certificate,
  CourseOverview,
  CourseQuizAnswer,
  CourseQuizAnswerPayload,
  CoursesContent,
  ErrorRes,
  LectureMarkedAsRead,
  MyClassAssignmentAnswer,
} from './learning-lab.model';

export const CourseContentActions = {
  // Course content
  loadCourseContent: createAction('[Course Content] Load', props<{ product_id: string; preview?: boolean }>()),
  loadCourseContentSuccess: createAction('[Course Content] Loaded Success', props<CoursesContent>()),
  loadCourseContentFail: createAction('[Course Content] Loaded Fail', props<{ error: ErrorRes }>()),

  // Course overview
  loadCourseOverview: createAction('[Course Overview] Load', props<{ product_id: string; preview?: boolean }>()),
  loadCourseOverviewSuccess: createAction('[Course Overview] Loaded Success', props<CourseOverview>()),
  loadCourseOverviewFail: createAction('[Course Overview] Loaded Fail', props<{ error: unknown }>()),
  resetCourseOverview: createAction('[Course Overview] Reset', props<{ overview: any }>()),

  // Next/Prev
  nextActivity: createAction('[Course Content] Next Activity'),
  prevActivity: createAction('[Course Content] Prev Activity'),

  // Select
  selectActivity: createAction(
    '[Course Content] Select Activity',
    props<{ sectionId: string; subsectionId: string }>()
  ),

  // Course content
  markedAsComplete: createAction('[Course Content] Marked', props<{ body: any; id: string }>()),
  markedAsCompleteSuccess: createAction('[Course Content] Marked Success'),
  markedAsCompleteFail: createAction('[Course Content] Marked Fail', props<{ error: unknown }>()),

  // Assignment Submit
  submitQuiz: createAction(
    '[Course Content] Submit Quiz',
    props<{ courseId: string; quizId: string; payload: CourseQuizAnswerPayload }>()
  ),
  submitQuizSuccess: createAction('[Course Content] Submit Quiz Success', props<{ data: CourseQuizAnswer[] }>()),
  submitQuizError: createAction('[Course Content] Submit Quiz Error', props<{ error: unknown }>()),

  // Assignment Submit
  submitAssignment: createAction(
    '[Course Content] Submit Assignment',
    props<{ courseId: string; assignId: string; payload: AssignmentSubmissionPayload }>()
  ),
  submitAssignmentSuccess: createAction(
    '[Course Content] Submit Assignment Success',
    props<{ data: MyClassAssignmentAnswer[] }>()
  ),
  submitAssignmentError: createAction('[Course Content] Submit Assignment Error', props<{ error: unknown }>()),

  // Certificate
  loadCourseCertificate: createAction('[Certificate] Load', props<{ product_id: string; params?: {} }>()),
  loadCourseCertificateSuccess: createAction('[Certificate] Loaded Success', props<Certificate>()),
  loadCourseCertificateFail: createAction('[Certificate] Loaded Fail', props<{ error: unknown }>()),

  // Update section check marked
  markedAsReadSuccess: createAction('[Course Content] Lecture Marked As Read Success', props<LectureMarkedAsRead>()),
};
