import { Subject } from 'rxjs';

export class ScrollTracker {
  private scrollingDown$ = new Subject<boolean>();
  private previousScrollTop = 0;

  /**
   *
   * @param scrollableElement If `undefined`, this tracker will get use `window` global object for tracking the scroll behaviour
   */
  constructor(
    private scrollableElement?: HTMLElement
  ) {}

  onScroll() {
    const scrollTop = this.scrollTop;
    if (scrollTop > this.previousScrollTop) {
      this.scrollingDown$.next(true)
    }
    this.previousScrollTop = scrollTop;
  }

  onScrollend(): void {
    this.scrollingDown$.next(false);
  }

  get scrollingDown() {
    return this.scrollingDown$.asObservable();
  }

  private get scrollTop() {
    return this.scrollableElement ? this.scrollableElement.scrollTop : window.scrollY;
  }
}
