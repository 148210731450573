import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  InputData,
  LectureVideoCaption,
  MyClassService,
  selectActiveSubsection,
  ThkVideoPlayerComponent,
} from '@shared';
import { BehaviorSubject, combineLatest, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { LectureDetailV2, PartialEntity, RouterStoreService, ToastService } from 'thkee-common';
// declare var videojs: any; // Declare videojs as a global variable

@UntilDestroy()
@Component({
  selector: 'app-learning-lab-activity-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class LearninglabVideoComponent implements OnInit {
  @Output() event = new EventEmitter<{ action: string; data: any }>();
  @Input() hidePrev: boolean = false;
  @Input() hideNext: boolean = false;
  @Input() prevData: any = null;
  @Input() nextData: any = null;
  isLoading: boolean = false;
  @ViewChild('video') videoElement!: ThkVideoPlayerComponent;

  data?: PartialEntity<LectureDetailV2>;
  sources$ = new BehaviorSubject<any>({});
  related_videos = [
    {
      thumb: '//domain.com/path/to/video_thumb_1.jpg',
      url: '//domain.com/path/to/video_1.html',
      title: 'Video 1 title',
      duration: '05:30',
    },
    {
      thumb: '//domain.com/path/to/video_thumb_2.jpg',
      url: '//domain.com/path/to/video_2.html',
      title: 'Video 2 title',
      duration: '05:30',
    },
    {
      thumb: '//domain.com/path/to/video_thumb_3.jpg',
      url: '//domain.com/path/to/video_3.html',
      title: 'Video 3 title',
      duration: '05:30',
    },
    {
      thumb: '//domain.com/path/to/video_thumb_4.jpg',
      url: '//domain.com/path/to/video_4.html',
      title: 'Video 4 title',
      duration: '05:30',
    },
    {
      thumb: '//domain.com/path/to/video_thumb_5.jpg',
      url: '//domain.com/path/to/video_5.html',
      title: 'Video 5 title',
      duration: '05:30',
    },
    {
      thumb: '//domain.com/path/to/video_thumb_6.jpg',
      url: '//domain.com/path/to/video_6.html',
      title: 'Video 6 title',
      duration: '05:30',
    },
  ];

  counter: number = 3;
  params: Params = {};
  isLoadingSource: boolean = false;
  videoTimelinePosition = 0;

  constructor(
    private el: ElementRef,
    private store: Store,
    private myClassService: MyClassService,
    private routerStore: RouterStoreService,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.routerStore
      .getParams()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((params) => {
        this.params = params;
        if ((this.params['overviewcode'], this.params['courseId'])) {
          this.getVideoPosition();
        }
      });
    // this.store
    //   .select(selectActiveSubsection)
    //   .pipe(distinctUntilChanged(), untilDestroyed(this))
    //   .subscribe((res: any) => {
    //     this.data = res?.lecture;
    //     // if (res?.lecture?.id) {
    //     //   this.getTranscodedInfo(this.params['courseId'], res.lecture.id);
    //     // }
    //   });

    // if (Object.keys(this.params).length && this.data) {
    //   this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
    //     if (params['preview'] === 'true') {
    //       if (this.data?.id) {
    //         this.getPreviewTranscodedInfo(this.data?.id);
    //       }
    //     } else {
    //       if (this.data?.id) {
    //         this.getTranscodedInfo(this.params['courseId'], this.data?.id);
    //       }
    //     }
    //   });
    // }

    combineLatest([this.routerStore.getQuery('preview'), this.store.select(selectActiveSubsection)])
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(([preview, res]) => {
        this.data = res?.lecture;
        if (preview) {
          if (this.data?.id) {
            this.getPreviewTranscodedInfo(this.data?.id);
          }
        } else {
          if (this.data?.id) {
            this.getTranscodedInfo(this.params['courseId'], this.data?.id);
          }
        }
      });
  }

  lectureVideoCaptions: LectureVideoCaption[] = [];
  getVideoPosition() {
    firstValueFrom(this.store.select(selectActiveSubsection)).then((subsection) => {
      // Video position
      this.myClassService
        .getVideoPosition(this.params['overviewcode'], subsection?.id || '')
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe({
          next: (res) => {
            this.videoTimelinePosition = res.watched_duration;
          },
          error: ({ error }) => {
            this.toastService.error({
              message: error.error,
            });
          },
        });

      // Video captions
      this.myClassService
        .getVideoCaptions(this.params['courseId'], subsection?.lecture?.id || '')
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe({
          next: (res) => {
            this.lectureVideoCaptions = res;
          },
          error: ({ error }) => {
            this.toastService.error({
              message: error.error,
            });
          },
        });
    });
  }

  timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
  intervalId: ReturnType<typeof setInterval> | undefined = undefined;

  videoEvent(event: { action: string; data: InputData }) {
    this.emitEvent(event.action, event.data);
    if (event.action === 'play') {
      this.counter = 3;
      this.isLoading = false;

      // Clear interval if it exists (prevent multiple intervals)
      if (this.intervalId !== undefined) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
      }
    } else if (event.action === 'ended') {
      this.isLoading = true;
      this.intervalId = setInterval(() => {
        this.counter--;
        if (this.counter === 0) {
          clearInterval(this.intervalId);
          this.intervalId = undefined;
        }
      }, 1000);

      this.timeoutId = setTimeout(() => {
        this.isLoading = false;
        this.emitEvent('next', { subsectionId: this.data?.section });
      }, 4000);
    }
  }

  onPlay() {
    // Cancel the setTimeout if it's running
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }

    // Clear the interval if it's running
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    this.videoElement.onPlay();
  }

  emitEvent(action: string, data: any = {}) {
    this.event.emit({ action, data });
  }

  // Get transcoded video info
  private getTranscodedInfo(productId: string, lectureId: string) {
    this.isLoadingSource = true;
    this.myClassService
      .getLectureTranscodedInfo(productId, lectureId)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.sources$.next(res);
          this.isLoadingSource = false;
        },
        error: (err) => {
          this.isLoadingSource = false;
          console.log(err);
        },
      });
  }

  // Get transcoded video preview info
  private getPreviewTranscodedInfo(lectureId: string) {
    this.isLoadingSource = true;
    this.myClassService
      .getPreviewLectureTranscodedInfo(lectureId)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.sources$.next(res);
          this.isLoadingSource = false;
        },
        error: (err) => {
          this.isLoadingSource = false;
          console.log(err);
        },
      });
  }
}
