import { Injectable } from '@angular/core';
import { BecomeInstructor, ForgotPassword, ResetPassword, Signup } from '@shared/models';
import { AuthInfo } from '@shared/store';
import {
  API_RESET_PASSWORD_GET_USER,
  API_REVERIFY_ACCOUNT,
  API_USERS_AUTH_INFO,
  API_USERS_BECOMEINSTRUCTOR,
  API_USERS_DETAIL,
  API_USERS_FORGOT_PASSWORD,
  API_USERS_LOGIN,
  API_USERS_LOGOUT,
  API_USERS_RESET_PASSWORD,
  API_USERS_SIGNUP,
  API_USERS_UPDATE_ME,
  API_USERS_UPDATE_ME_EMAIL,
  API_USERS_UPDATE_PASSWORD,
  API_USERS_VERIFY_LINK_TOKEN,
} from 'projects/thkee-common/src/lib/models/constants';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import {
  Credentials,
  CredentialsService,
  HttpService,
  LoaderService,
  LoginContext,
  LoginResponse,
  User,
  hashPassword,
} from 'thkee-common';

interface LoginActionState {
  openLoginModal?: boolean;
  redirect?: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  loginActionState = new BehaviorSubject<LoginActionState>({});

  constructor(
    private credentialsService: CredentialsService,
    private readonly http: HttpService,
    private loaderService: LoaderService
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext, trackerId?: string): Observable<Credentials> {
    return this.http
      .post<LoginResponse, LoginContext>(
        API_USERS_LOGIN,
        { email: context.email, password: hashPassword(context.password, context.email) },
        undefined,
        { trackerId: trackerId }
      )
      .pipe(
        map((res) => {
          const credentials: Credentials = {
            refresh: res.refresh,
            token: res.access,
          };
          this.credentialsService.setCredentials(credentials, context.remember);
          return credentials;
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.http.post(API_USERS_LOGOUT, {}).subscribe();
    this.credentialsService.setCredentials();
    return of(true);
  }

  signup(
    signupData: { email: string; password: string; name: string; geo?: Partial<Geolocation> },
    options?: { contextId?: string }
  ): Observable<void> {
    const { email, password, name, geo } = signupData;
    const [firstName, ...lastNames] = name.split(/\s+/);
    const data: Signup = {
      first_name: firstName,
      last_name: lastNames.join(' '),
      email,
      username: email,
      password: hashPassword(password, email),
      ...geo,
    };
    return this.http.post<void>(API_USERS_SIGNUP, data, undefined, { trackerId: options?.contextId });
  }

  // Become instructor registration or signup
  signupInstructor(user: string, other: any): Observable<void> {
    const data: BecomeInstructor = {
      user: user,
      instructor_consent: other,
    };
    return this.http.post<void>(API_USERS_BECOMEINSTRUCTOR, data);
  }

  forgotPassword(data: ForgotPassword): Observable<void> {
    return this.http.post<void>(API_USERS_FORGOT_PASSWORD, data);
  }

  getUser(): Observable<User> {
    if (this.credentialsService.hasCredentials()) {
      return this.http.get<User>(API_USERS_DETAIL);
    } else {
      return of();
    }
  }

  updateUser(user: Partial<User>, options?: { contextId?: string }): Observable<User> {
    return this.http.put<User>(API_USERS_UPDATE_ME, user, undefined, { trackerId: options?.contextId });
  }

  updateEmail(email: string): Observable<User> {
    return this.http.put(API_USERS_UPDATE_ME_EMAIL, { email });
  }

  verifyEmailToken(action: string, token: string): Observable<void> {
    // return of(undefined); // fake success action
    return this.http.put(API_USERS_VERIFY_LINK_TOKEN, { action, token });
  }

  updatePassword(oldPassword: string, newPassword: string): Observable<User> {
    return this.http.post(API_USERS_UPDATE_PASSWORD, {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: newPassword,
    });
  }

  getUserByResetPasswordToken(code: string): Observable<User> {
    // return of({ first_name: 'Hai Le', email: 'haile.vnm@gmail.com' } as any)
    return this.http.post<User>(API_RESET_PASSWORD_GET_USER, { code });
  }

  resetPassword(data: ResetPassword, options?: { trackerId: string }) {
    // return of({})
    return this.http.put(API_USERS_RESET_PASSWORD, data, undefined, { trackerId: options?.trackerId });
  }

  reverifyEmail(trackerId?: string) {
    return this.http.post(API_REVERIFY_ACCOUNT, undefined, undefined, { trackerId: trackerId });
  }

  getAuthInfo(): Observable<AuthInfo> {
    return this.http.get<AuthInfo>(API_USERS_AUTH_INFO);
  }
}
