import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_DELETE_INSTRUCTOR_COURSE_SETTINGS_DELETE,
  API_GET_INSTRUCTOR_COURSE_SETTINGS,
  API_GET_INSTRUCTOR_COURSES_EMAIL_PREFERENCE,
  API_GET_STUDENT_COURSES_EMAIL_PREFERENCE,
  API_POST_INSTRUCTOR_COURSE_SETTINGS_LISTING,
  API_POST_INSTRUCTOR_COURSES_EMAIL_PREFERENCE,
  API_POST_STUDENT_COURSES_EMAIL_PREFERENCE,
  HttpService,
} from 'thkee-common';

export interface CourseSettings {
  title: string;
  status: string;
  is_unlisted: boolean;
  can_be_delete: boolean;
}

export interface StudentCoursesEmailPreference {
  receive_course_promotional_announcements_emails: boolean;
  receive_course_educational_announcements_emails: boolean;
  project: {
    id: string;
    course: Course;
  };
}
export interface InstructorCoursesEmailPreference {
  receive_monthly_new_student_enrollment: boolean;
  receive_lecture_ready_emails: boolean;
  receive_daily_digest_emails: boolean;
  project: {
    id: string;
    course: Course;
  };
}

interface Course {
  id: string;
  title: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class CourseSettingsService {
  constructor(private readonly http: HttpService) {}

  getCourseSettings(course_id: string): Observable<CourseSettings> {
    return this.http.get<CourseSettings>(API_GET_INSTRUCTOR_COURSE_SETTINGS.replace('<COURSE_ID>', course_id));
  }

  courseSettingsListing(course_id: string): Observable<void> {
    return this.http.post(API_POST_INSTRUCTOR_COURSE_SETTINGS_LISTING.replace('<COURSE_ID>', course_id), {});
  }

  courseSettingsDelete(course_id: string): Observable<void> {
    return this.http.delete(API_DELETE_INSTRUCTOR_COURSE_SETTINGS_DELETE.replace('<COURSE_ID>', course_id));
  }

  getStudentNotificationSettings(course_id: string): Observable<StudentCoursesEmailPreference> {
    return this.http.get<StudentCoursesEmailPreference>(
      API_GET_STUDENT_COURSES_EMAIL_PREFERENCE.replace('<COURSE_ID>', course_id)
    );
  }

  updateStudentNotificationSettings(payload = {} as object) {
    return this.http.post(API_POST_STUDENT_COURSES_EMAIL_PREFERENCE, payload);
  }

  getInstructorNotificationSettings(course_id: string): Observable<InstructorCoursesEmailPreference> {
    return this.http.get<InstructorCoursesEmailPreference>(
      API_GET_INSTRUCTOR_COURSES_EMAIL_PREFERENCE.replace('<COURSE_ID>', course_id)
    );
  }

  updateInstructorNotificationSettings(payload = {} as object) {
    return this.http.post(API_POST_INSTRUCTOR_COURSES_EMAIL_PREFERENCE, payload);
  }
}
