import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { selectActiveSubsection } from '@shared';
import * as _ from 'lodash';
import { Observable, distinctUntilChanged } from 'rxjs';

export interface score {
  total: number;
  incorrect: number;
  correct: number;
  skipped: number;
  percentage: {
    correct: number;
    incorrect: number;
    skipped: number;
  };
}

export interface scoreList {
  id: string;
  question: string;
  isCorrect: true | false | 'skipped';
}

@UntilDestroy()
@Component({
  selector: 'app-learning-lab-activity-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class LearninglabQuizComponent implements OnInit {
  @ViewChild('qForm') qForm!: NgForm;
  @Output() event = new EventEmitter<{ action: string; data: any }>();
  @Input() prev!: string;
  @Input() next!: string;
  @Input() title: string = $localize`Title`;
  @Input() hidePrev: boolean = false;
  @Input() hideNext: boolean = false;

  data?: any;
  // data?: PartialEntity<QuizDetailV2>;
  quizState: '' | 'started' | 'completed' = '';
  currentQuestion: number = 0;
  currentQuestionId: any = '';
  skippedQuestion: number = 0;

  totalScore: score = {
    total: 0,
    incorrect: 0,
    correct: 0,
    skipped: 0,
    percentage: {
      correct: 0,
      incorrect: 0,
      skipped: 0,
    },
  };
  scoreList: scoreList[] = [];

  readonly form: FormGroup = new FormGroup({});
  model: Partial<any> = {};
  fields: FormlyFieldConfig[] = [];

  getQuizAnswers$!: Observable<any>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectActiveSubsection)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((quizDetail: any) => {
        this.data = quizDetail;
        this.title = quizDetail?.quiz?.title ?? '';
        this.quizState = '';
        this.currentQuestion = 0;
        this.currentQuestionId = '';
        this.skippedQuestion = 0;
        this.totalScore = {
          total: 0,
          incorrect: 0,
          correct: 0,
          skipped: 0,
          percentage: {
            correct: 0,
            incorrect: 0,
            skipped: 0,
          },
        };

        if (quizDetail) {
          const filterAnswered: any[] = quizDetail?.submission || [];
          if (filterAnswered && filterAnswered.length) {
            let newModel: any = {};
            filterAnswered.forEach((answer: any) => {
              newModel[answer.question] = answer.answer;
            });
            this.model = newModel;
            this.quizState = 'completed';
            this.computeScore();
          } else {
            this.quizState = '';
          }
        }
        this.initfield();
      });
  }

  initfield() {
    const fields: FormlyFieldConfig[] = (this.data?.quiz?.questions || []).map(
      (question: { id: any; question: any; answers: any }) => ({
        key: question.id,
        type: 'radio',
        props: {
          label: question.question,
          required: true,
          options: (question.answers || []).map((answer: { id: any; answer: any }) => ({
            value: answer.id,
            label: answer.answer,
          })),
        },
        expressions: {
          className: (field: FormlyFieldConfig) => {
            const className = this.currentQuestionId != field.key ? 'hidden' : '';
            return className;
          },
        },
      })
    );
    this.currentQuestionId = fields[this.currentQuestion]?.key;
    this.fields = fields;
  }

  startQuiz() {
    this.emitEvent('quiz_start', this);
    this.quizState = 'started';
    this.initfield();
  }

  nextQuestion() {
    if (!this.model[this.currentQuestionId]) {
      this.emitEvent('quiz_answer_invalid', this);
      this.form.markAsTouched();
      (this.qForm as any).submitted = true;
    } else {
      this.form.markAsTouched();
      (this.qForm as any).submitted = true;
      this.currentQuestion = this.currentQuestion + 1;
      if (this.fields[this.currentQuestion]) {
        this.currentQuestionId = this.fields[this.currentQuestion].key;
        this.form.markAsUntouched();
        (this.qForm as any).submitted = false;
        this.emitEvent('quiz_question_next', this);
      } else {
        this.quizFinished();
      }
    }
  }

  skipQuestion() {
    this.emitEvent('quiz_question_skip', this);
    this.form.markAsTouched();
    (this.qForm as any).submitted = true;
    this.model[this.currentQuestionId] = '';
    this.currentQuestion = this.currentQuestion + 1;
    this.skippedQuestion = this.skippedQuestion + 1;
    if (this.fields[this.currentQuestion]) {
      this.currentQuestionId = this.fields[this.currentQuestion].key;
      this.form.markAsUntouched();
      (this.qForm as any).submitted = false;
    } else {
      this.quizFinished();
    }
  }

  quizFinished() {
    this.emitEvent('quiz_completed', this);
    this.quizState = 'completed';
    this.form.markAsUntouched();
    (this.qForm as any).submitted = false;
    this.computeScore();
  }

  retryQuiz() {
    this.quizState = '';
    this.currentQuestion = 0;
    this.currentQuestionId = '';
    this.skippedQuestion = 0;
    this.model = {};
    this.emitEvent('quiz_retry', this);
  }

  nextLecture() {
    this.emitEvent('next', this);
    // this.model = {}; // After submit clear the model
  }

  skipQuiz() {
    this.emitEvent('next', this);
  }

  computeScore() {
    if (this.data?.quiz?.questions) {
      this.scoreList = this.data.quiz.questions.map(
        (question: { answers: any; id: string | number; question: any }) => {
          const getCorrect = _.find(question.answers, { is_correct: true });
          const isCorrect =
            this.model[question.id] && getCorrect?.id === this.model[question.id]
              ? true
              : this.model[question.id]
              ? false
              : 'skipped';

          return {
            id: question.id,
            question: question.question,
            isCorrect,
          };
        }
      );

      const totalCount = this.scoreList.length;
      const trueCount = this.scoreList.filter((item) => item.isCorrect === true).length;
      const falseCount = this.scoreList.filter((item) => item.isCorrect === false).length;
      const skippedCount = this.scoreList.filter((item) => item.isCorrect === 'skipped').length;

      this.totalScore = {
        total: totalCount,
        incorrect: falseCount,
        correct: trueCount,
        skipped: skippedCount,
        percentage: {
          correct: (trueCount / totalCount) * 100,
          incorrect: (falseCount / totalCount) * 100,
          skipped: (skippedCount / totalCount) * 100,
        },
      };
    }
  }

  emitEvent(action: string, data: any = {}) {
    this.event.emit({ action, data });
  }
}
