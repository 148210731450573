import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FilterOptionComponent, FilterOptionComponentEventOutput } from '@shared';
import { get, isEqual, sortBy } from 'lodash';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs';
import {
  CardPricingService,
  CategoryV2,
  Course,
  CourseListPayload,
  CoursesService,
  FilterCourseOptions,
  FilterOption,
  LANGUAGES,
  PriceLoader,
  selectQueryParams,
} from 'thkee-common';

const SORT_BY_ALL_OPTION_VALUE = 'all';

const DEFAULT_FILTER_GROUPS = {
  price: [
    { value: 'Paid', label: $localize`Paid` },
    { value: 'Free', label: $localize`Free` },
  ],
  courselength: [
    { value: '60', label: $localize`${'0 - 1'}:range_hour_number: hour` },
    { value: '180', label: $localize`${'1 - 3'}:range_hour_number: hours` },
    { value: '360', label: $localize`${'3 - 6'}:range_hour_number: hours` },
    { value: '600', label: $localize`${'6 - 10'}:range_hour_number: hours` },
    { value: '100000', label: $localize`${10}:amount:+ hours` },
  ],
  rating: [
    { value: '4.5', label: $localize`${4.5}:rating_number: & up` },
    { value: '4', label: $localize`${4.0}:rating_number: & up` },
    { value: '3.5', label: $localize`${3.5}:rating_number: & up` },
    { value: '3', label: $localize`${3.0}:rating_number: & up` },
  ],
  level: [
    { value: 'All', label: $localize`All Levels` },
    { value: 'Beginner', label: $localize`Beginner` },
    { value: 'Intermediate', label: $localize`Intermediate` },
    { value: 'Expert', label: $localize`Expert` },
  ],
  language: sortBy(
    Object.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
    (lang) => lang.label
  ),
};

@UntilDestroy()
@Component({
  selector: 'app-courses-filter',
  templateUrl: './courses-filter.component.html',
  styleUrls: ['./courses-filter.component.scss'],
})
export class CoursesFilterComponent implements OnInit {
  @Input() anchor: Observable<Record<string, unknown>> = new BehaviorSubject({}).asObservable();
  @Input() category?: CategoryV2;
  @Input() set searchText(text: string) {
    this.searchKeyword = text;
  }
  @Output() searchedTextChange = new EventEmitter<string>();
  @Output() totalCourses = new EventEmitter<number>();

  @ViewChildren(FilterOptionComponent) filterComps!: FilterOptionComponent[];

  courses: Partial<Course>[] = [];
  coursesLoaded = false;
  filterCoursesLoader!: PriceLoader;

  hideFilter: Record<string, boolean> = {};
  initialFilterValue: FilterOptionComponentEventOutput['data'] = {};
  totalCoursesAmount?: number;

  // Sort By
  sortByModel = { filter: '' };
  sortByForm = new FormGroup({});
  fieldsFilter: FormlyFieldConfig[] = [
    {
      key: 'filter',
      type: 'select',
      props: {
        options: [
          { label: $localize`All`, value: 'all' },
          { label: $localize`Popular`, value: 'popular' },
          { label: $localize`Newest`, value: 'newest' },
          { label: $localize`Highest Rated`, value: 'highest-rated' },
          // { label: $localize`Unlisted`, value: 'unlisted' },
        ],
        label: 'sample',
        hideLabel: true,
        stylish: true,
        wrapClass: 'block mb-0',
      },
      hooks: {
        onInit: (field) => {
          // On change event
          if (field.formControl) {
            field.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.sortCourses());
          }
        },
      },
    },
  ];
  showFilter: boolean = false;
  formattedFilterOptions: FilterCourseOptions = {};
  searchKeyword = '';
  topicFilterOptions: FilterOption[] = [];

  private filterValues$ = new BehaviorSubject<Record<string, any> | undefined>(undefined);
  private firstNavigating = true;
  private nextPageUrl!: string;
  private anchorCondition: Record<string, unknown> = {};
  private queriedBaseCondition?: Record<string, unknown>;

  private get sortByQuery() {
    const value = this.sortByForm.get('filter')?.value || SORT_BY_ALL_OPTION_VALUE;
    return { sort_by: value === SORT_BY_ALL_OPTION_VALUE ? undefined : value };
  }

  constructor(
    private readonly store: Store,
    private readonly coursesService: CoursesService,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cardPricingService: CardPricingService
  ) {}

  ngOnInit(): void {
    this.initializeUiOptionFromQueryString();
    this.updateQueryStringWhenChangingFilterOptionUI();
    this.refreshCoursesOnQueryChanged();
  }

  filterHandler(event: FilterOptionComponentEventOutput) {
    this.filterValues$.next({
      ...this.filterValues$.getValue(),
      ...event.data,
    });
  }

  clearFilter() {
    this.searchKeyword = '';
    this.resetFilterOptionsComps();
  }

  searchCourses() {
    this.router.navigate([], {
      queryParams: { search: this.searchKeyword || undefined },
      queryParamsHandling: 'merge',
    });
  }

  private updateQueryStringWhenChangingFilterOptionUI() {
    this.filterValues$.pipe(filter(Boolean), debounceTime(300), untilDestroyed(this)).subscribe((values) => {
      const queryParams = {
        ...this.toQueryString(values),
        ...this.sortByQuery,
        search: this.searchKeyword || undefined,
      };

      this.router.navigate([], { queryParams });
    });
  }

  private toQueryString(obj: Record<string, Record<string, boolean>>): Record<string, string> {
    return Object.entries(obj).reduce((pre, [groupName, groupData]) => {
      const queryString = Object.entries(groupData || {})
        .filter(([key, v]) => key && v)
        .map(([key]) => key)
        .join(',');

      if (!queryString) {
        return pre;
      }
      return Object.assign(pre, { [groupName]: queryString });
    }, {});
  }

  private toFormlyFormData(obj: Record<string, string>): FilterOptionComponentEventOutput['data'] {
    const originalObj: FilterOptionComponentEventOutput['data'] = {};
    for (const key in obj) {
      const keys = obj[key].split(',');
      originalObj[key] = {};
      keys.forEach((innerKey) => {
        originalObj[key][innerKey] = true;
      });
    }
    return originalObj;
  }

  // Load More
  loadMoreCourse() {
    if (this.coursesLoaded && this.nextPageUrl) {
      const queryString = this.nextPageUrl.split('?')[1];
      const nextFilter = this.parseQueryString(queryString);
      this.loadCourses(nextFilter);
    }
  }

  private parseQueryString(queryString: string) {
    // Remove leading '?' if present
    queryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;

    // Split the query string into an array of key-value pairs
    const paramsArray = queryString.split('&');

    // Initialize an empty object to store the parameters
    const paramsObject: CourseListPayload = {};

    // Loop through each key-value pair and add it to the object
    paramsArray.forEach((pair) => {
      const [key, value] = pair.split('=');
      paramsObject[key] = decodeURIComponent(value);
    });

    return paramsObject;
  }

  private resetFilterOptionsComps() {
    if (this.filterComps) {
      this.filterComps.forEach((com) => com.reset());
    }
  }

  private sortCourses() {
    this.router.navigate([], { queryParams: this.sortByQuery, queryParamsHandling: 'merge' });
  }

  private initializeUiOptionFromQueryString() {
    this.store
      .select(selectQueryParams)
      .pipe(take(1))
      .subscribe((query) => {
        this.searchKeyword = query['search'];
        this.sortByModel = { filter: query['sort_by'] || SORT_BY_ALL_OPTION_VALUE };
        this.initialFilterValue = this.toFormlyFormData(query);
      });
  }

  private refreshCoursesOnQueryChanged() {
    this.prepareDataOnAnchorDataChanged()
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(() => this.store.select(selectQueryParams).pipe(filter(Boolean))),
        debounceTime(300),
        untilDestroyed(this)
      )
      .subscribe((query) => this.refreshCourses(query));
  }

  private prepareDataOnAnchorDataChanged() {
    return this.anchor.pipe(
      switchMap((data) => {
        this.anchorCondition = data;
        if (this.firstNavigating) {
          this.searchKeyword = this.activatedRoute.snapshot.queryParams['search'];
          this.firstNavigating = false;
        } else {
          this.clearFilter();
        }

        return this.setTopicFilterOptions();
      })
    );
  }

  private refreshCourses(routerQuery: Record<string, string>) {
    this.courses = [];
    const baseCondition = { ...this.anchorCondition, ...(this.searchKeyword && { search: this.searchKeyword }) };

    const shouldReloadFilterOptionAmount = !isEqual(baseCondition, this.queriedBaseCondition);
    const isSearchTextChanged = !isEqual(this.searchKeyword || '', baseCondition.search || '');
    if (shouldReloadFilterOptionAmount) {
      this.fetchFilterOptionAmount().subscribe();
      this.queriedBaseCondition = baseCondition;
    }

    if (isSearchTextChanged) {
      this.searchedTextChange.emit(this.searchKeyword);
    }

    const query: CourseListPayload = {
      page: 1,
      page_size: 9,
      ...routerQuery,
      ...baseCondition,
    };

    this.loadCourses(query, true);
  }

  private loadCourses(filter: CourseListPayload, reset = false) {
    this.coursesLoaded = false;
    this.coursesService.getCourses(filter).subscribe((data) => {
      this.courses = [...(reset ? [] : this.courses), ...data.results];
      this.filterCoursesLoader = this.cardPricingService.fetchCoursesPrices(this.courses);

      this.nextPageUrl = data.next || '';
      this.totalCoursesAmount = data.count;
      this.totalCourses.emit(data.count);

      this.coursesLoaded = true;

      return data.results;
    });
  }

  private fetchFilterOptionAmount() {
    return this.coursesService
      .getCourses({
        page: 1,
        page_size: 1, // the minimum because we only need the filter_options in the response only
        ...this.anchorCondition,
        ...(this.searchKeyword && { search: this.searchKeyword }),
      })
      .pipe(map(({ filter_options }) => this.updateFilerOptionCount(filter_options)));
  }

  private get filterOptions(): Record<string, FilterOption[]> {
    return {
      ...DEFAULT_FILTER_GROUPS,
      subcategory: this.getSubcategoriesFilterOptions(),
      topic: this.topicFilterOptions,
    };
  }

  private updateFilerOptionCount(optionCount: FilterCourseOptions = {}) {
    const objectOptionCount: Record<string, Record<string, FilterOption>> = Object.entries(this.filterOptions).reduce(
      (pre, [groupName, values]) => {
        return Object.assign(pre, {
          [groupName]: (values || []).reduce((pree, item) => Object.assign(pree, { [item.value]: item }), {}),
        });
      },
      {}
    );
    const mappingResponseDataAndUiFields = {
      types: 'price',
      duration: 'courselength',
      languages: 'language',
      ratings: 'rating',
      skill_levels: 'level',
      subcategories: 'subcategory',
      topics: 'topic',
    };
    optionCount['subcategories'] = get(optionCount, ['categories', 0, 'subcategories'], []);

    this.formattedFilterOptions = Object.entries(optionCount).reduce((pre, [key, values]) => {
      const uiField = mappingResponseDataAndUiFields[key];
      if (!uiField) {
        return pre;
      }
      const mappedValues = values
        .filter((item) => !!item.count)
        .map((item) => {
          const uiOption = get(objectOptionCount, [uiField, item.value]);
          const label = uiOption?.label;

          return { ...uiOption, label: `${label} <span class="text-slate-400">(${item.count})</span>` };
        });
      return Object.assign(pre, { [uiField]: mappedValues });
    }, {});
  }

  private getSubcategoriesFilterOptions() {
    if (this.category) {
      return (this.category.subcategories || []).map((item) => ({
        value: item.slug,
        label: item.name,
      }));
    }

    return [];
  }

  private setTopicFilterOptions() {
    return this.coursesService.getTopics(this.anchorCondition).pipe(
      map((res) => {
        this.topicFilterOptions = sortBy(
          Object.values(res).map((topic) => ({ label: topic.name, value: topic.slug })),
          (topic) => topic.label
        );

        return this.topicFilterOptions;
      })
    );
  }
}
