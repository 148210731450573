<ng-container *ngIf="isSourceFound; else sourceFound">
  <video
      id="video_holder"
      #video
      height="100%"
      width="100%"
      class="video-js vjs-fluid vjs-lock-showing"
      controls
      playsinline="true"
      preload="auto"
      [autoplay]="autoplay"
    ></video>
</ng-container>
<ng-template #sourceFound>
  <div class="h-full w-full absolute flex items-center justify-center left-0">
    <h3 class="text-base text-slate-500 font-medium" i18n>No video source founded</h3>
  </div>
</ng-template>