import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CourseOverview, CoursesContent, MyClassService } from '@shared';
import * as _ from 'lodash';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { CoursesService, LoaderService, RouterStoreService } from 'thkee-common';
import { CourseContentActions } from './learning-lab.actions';

interface ErrorRes {
  error: any;
  headers: any;
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
}
@Injectable()
export class CourseContentEffects {
  params: Params = {};

  constructor(
    private routerStore: RouterStoreService,
    private store: Store,
    private actions$: Actions,
    private myClassService: MyClassService,
    private router: Router,
    private coursesService: CoursesService,
    private location: Location,
    private loaderService: LoaderService
  ) {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
    });
  }

  coursesContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseContentActions.loadCourseContent),
      switchMap((payload) => {
        setTimeout(() => {
          this.loaderService.loaderAction('learning-lab-sidebar', 'loading');
        });
        return this.myClassService.getClassesContent(payload).pipe(
          map((data: CoursesContent) => {
            let sectionData = _.groupBy(
              data.results?.flatMap((section: { subsections: any }) => section.subsections),
              (subsection) => subsection
            );
            data.subsections = Object.values(sectionData)[0];
            this.loaderService.loaderAction('learning-lab-sidebar', 'success');
            return CourseContentActions.loadCourseContentSuccess(data);
          }),
          catchError((error) => of(CourseContentActions.loadCourseContentFail({ error })))
        );
      })
    )
  );

  loadCourseContentFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CourseContentActions.loadCourseContentFail),
        tap(({ error }) => {
          if (error.status === 401) {
            window.location.replace(`/account/login`);
          }
          if (error.status === 404) {
            this.coursesService.getCourseData(this.params['courseId']).subscribe({
              next: (res) => {
                if (res?.slug) {
                  window.location.replace(`/course/${res.slug}`);
                }
              },
              error: (err) => {
                this.location.back();
              },
            });
          }
        })
      ),
    { dispatch: false }
  );

  coursesOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseContentActions.loadCourseOverview),
      switchMap((payload) => {
        return this.myClassService.getClassesOverview(payload).pipe(
          map((data: CourseOverview) => {
            return CourseContentActions.loadCourseOverviewSuccess(data);
          }),
          catchError((error) => of(CourseContentActions.loadCourseOverviewFail({ error })))
        );
      })
    )
  );

  coursesMarked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseContentActions.markedAsComplete),
      switchMap((body) => {
        return this.myClassService.markedAsRead(body.body, body.id).pipe(
          map((data: any) => {
            return CourseContentActions.markedAsCompleteSuccess();
          }),
          catchError((error) => of(CourseContentActions.markedAsCompleteFail({ error })))
        );
      })
    )
  );

  submitQuiz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseContentActions.submitQuiz),
      switchMap((data) => {
        return this.myClassService.submitQuiz(data.courseId, data.quizId, data.payload).pipe(
          map((data) => CourseContentActions.submitQuizSuccess({ data })),
          catchError((error) => of(CourseContentActions.submitQuizError({ error })))
        );
      })
    )
  );

  submitAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseContentActions.submitAssignment),
      switchMap((data) => {
        return this.myClassService.submitAssignmentAnswers(data.courseId, data.assignId, data.payload).pipe(
          map((data) => CourseContentActions.submitAssignmentSuccess({ data })),
          catchError((error) => of(CourseContentActions.submitAssignmentError({ error })))
        );
      })
    )
  );

  // Certificate
  coursesCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseContentActions.loadCourseCertificate),
      switchMap((payload) => {
        return this.myClassService.getCoursesCertificatet(payload.product_id, payload.params).pipe(
          map((data) => CourseContentActions.loadCourseCertificateSuccess(data)),
          catchError((error) => of(CourseContentActions.loadCourseCertificateFail({ error })))
        );
      })
    )
  );
}
